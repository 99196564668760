import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchFinancialsSummary = createAsyncThunk(
  "/fetchFinancialsSummary",
  async (plan_id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        `/fetch-conventional-plan-summary-region-wise/client-appsells/${plan_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch financials");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching financials"
      );
    }
  }
);

const fetchFinancialsSummarySlice = createSlice({
  name: "fetchFinancialsSummary",
  initialState: {
    data: {},
    status: "idle",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinancialsSummary.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchFinancialsSummary.fulfilled, (state, action) => {
        console.log("payload : " + JSON.stringify(action.payload));
        state.status = "succeeded";
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchFinancialsSummary.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default fetchFinancialsSummarySlice.reducer;
