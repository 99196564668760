import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

// Fetch campaign details
export const fetchCampaignDetails = createAsyncThunk(
  "campaign/fetchCampaignDetails",
  async ({ id, page, limit, searchParams }) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(
      `/fetch-single-campaign/admin/appsells/${id}?page=${page}&limit=${limit}`,
      {
        params: searchParams,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Fetch trackers
export const fetchTrackers = createAsyncThunk(
  "campaign/fetchTrackers",
  async () => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(`/admin/fetch-trackers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

// Fetch site
export const fetchSite = createAsyncThunk("site/fetchSite", async (id) => {
  const token = localStorage.getItem("authToken");
  const response = await axiosInstance.get(`/admin/fetch-site/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

// export const fetchPlanSite = createAsyncThunk(
//   "site/fetchPlanSite",
//   async (id, planId) => {
//     const token = localStorage.getItem("authToken");
//     const response = await axiosInstance.get(
//       `/admin/fetch-plansite/${id}`,

//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         params: {
//           plan_id: planId,
//         },
//       }
//     );

//     return response.data;
//   }
// );
export const fetchPlanSite = createAsyncThunk(
  "site/fetchPlanSite",
  async ({ site_id, plan_id ,date}) => {
    console.log("site id received is: " + site_id + " plan id: " + plan_id);
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(
      `/admin/fetch-plansite/${site_id}?plan_id=${plan_id}&date=${date}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  }
);

// Fetch approved images
export const approvedImagesSites = createAsyncThunk(
  "approved/images",
  async ({ id, date }) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(
      `/admin/fetch-approved-siteimages/${id}`,
      {
        params: { date },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Fetch pending images
export const fetchPendingImages = createAsyncThunk(
  "pending/images",
  async ({ id, date }) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(
      `/admin/fetch-pending-siteimages/${id}`,
      {
        params: { date },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Reject site images
export const rejectSiteImages = createAsyncThunk(
  "campaign/rejectSiteImages",
  async ({ id, data }) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.patch(
      `/admin/reject-images-site-tracker`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Approve site images
export const approveSiteImages = createAsyncThunk(
  "campaign/approveSiteImages",
  async ({ id, data }) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.patch(
      `/admin/approve-images-site-tracker`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Fetch statuses
export const fetchStatus = createAsyncThunk(
  "campaign/fetchStatus",
  async ({ site_id, plan_id }) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(
      `/admin/fetch-execution-statuses-admin/`,
      {
        params: {
          site_id,
          plan_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Approve execution status
export const approveExecutionStatus = createAsyncThunk(
  "campaign/approveExecutionStatus",
  async ({ site_id, plan_id }) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.post(
      `/admin/approve-execution-status/`,
      {},
      {
        params: { site_id, plan_id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Update execution status
export const updateExecutionStatus = createAsyncThunk(
  "campaign/updateExecutionStatus",
  async ({ execution_status_id, site_id, plan_id }) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.post(
      `/admin/update-execution-status`,
      {},
      {
        params: { execution_status_id, site_id, plan_id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Reject execution status
export const rejectExecutionStatus = createAsyncThunk(
  "campaign/rejectExecutionStatus",
  async ({ site_id, plan_id }) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.post(
      `/admin/reject-execution-status`,
      {},
      {
        params: { site_id, plan_id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Assign tracker to sites
export const assignTracker = createAsyncThunk(
  "campaign/assignTracker",
  async ({ site_ids, tracker_id }) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.post(
      `/admin/assign-tracker/appsells-sites`,
      { site_ids, tracker_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Fetch plan details
export const fetchPlan = createAsyncThunk("campaign/fetchPlan", async (id) => {
  const token = localStorage.getItem("authToken");
  const response = await axiosInstance.get(`/fetch-plan/admin-appsells/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

// Fetch brief details
export const fetchBrief = createAsyncThunk(
  "campaign/fetchBrief",
  async (id) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(
      `/fetch-single-brief-admin/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);
// Fetch campaign details
export const fetchTrackerStatuses = createAsyncThunk(
  "campaign/fetchTrackerStatuses",
  async () => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(`/fetch-tracker-status`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

// Update Tracker status
export const updateTrackerStatus = createAsyncThunk(
  "campaign/updateTrackerStatus",
  async ({ tracker_status_id, site_id, plan_id }) => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.put(
      `/update-tracker-status`,
      {},
      {
        params: {tracker_status_id, site_id, plan_id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);


const campaignSlice = createSlice({
  name: "campaign",
  initialState: {
    campaignDetails: {},
    trackers: [],
    site: {},
    approvedImages: [],
    pendingImages: [],
    statuses: [],
    plan: {}, // Add a new state for plan details
    brief: {}, // Add a new state for brief details
    status: "idle",
    error: null,
    loading: false,
    fetchCamapignLoading: true,
    pageCount: 0,
    total: 0,
    totalPages: 0,
    trackerStatuses: [],
    highres_link :{} , campaignloading : false,
  },
  reducers: {
    resetPlan: (state) => {
      state.plan = {};
    },
    resetBrief: (state) => {
      state.brief = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Campaign Details
      .addCase(fetchCampaignDetails.pending, (state) => {
        state.fetchCamapignLoading = true;
        state.campaignloading = true ;
      })
      .addCase(fetchCampaignDetails.fulfilled, (state, action) => {
        state.fetchCamapignLoading = false;
        state.campaignDetails = action.payload;
        state.pageCount = action.payload.pageCount;
        state.total = action.payload.total;
        state.totalPages = action.payload.totalPages;
        state.highres_link = action.payload.highres_link ;
        state.campaignloading = false ;
      })
      .addCase(fetchCampaignDetails.rejected, (state, action) => {
        state.fetchCamapignLoading = false;
        state.error = action.error.message;
        state.campaignloading = false ;

      })
      // Fetch Trackers
      .addCase(fetchTrackers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTrackers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trackers = action.payload;
      })
      .addCase(fetchTrackers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch Site
      .addCase(fetchSite.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSite.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.site = action.payload;
      })
      .addCase(fetchSite.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPlanSite.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPlanSite.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.site = action.payload;
      })
      .addCase(fetchPlanSite.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Approved Images Sites
      .addCase(approvedImagesSites.pending, (state) => {
        state.status = "loading";
      })
      .addCase(approvedImagesSites.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.approvedImages = action.payload;
      })
      .addCase(approvedImagesSites.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Pending Images Sites
      .addCase(fetchPendingImages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPendingImages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pendingImages = action.payload;
      })
      .addCase(fetchPendingImages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Reject Site Images
      .addCase(rejectSiteImages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(rejectSiteImages.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Handle the response if needed
      })
      .addCase(rejectSiteImages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Approve Site Images
      .addCase(approveSiteImages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(approveSiteImages.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Handle the response if needed
      })
      .addCase(approveSiteImages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch Status
      .addCase(fetchStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.statuses = action.payload;
      })
      .addCase(fetchStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch Tracker Statues
      .addCase(fetchTrackerStatuses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTrackerStatuses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trackerStatuses = action.payload;
      })
      .addCase(fetchTrackerStatuses.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Update Tracker's Status
      .addCase(updateTrackerStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTrackerStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Handle the response if needed
      })
      .addCase(updateTrackerStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Approve Execution Status
      .addCase(approveExecutionStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(approveExecutionStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Handle the response if needed
      })
      .addCase(approveExecutionStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Update Execution Status
      .addCase(updateExecutionStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateExecutionStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Handle the response if needed
      })
      .addCase(updateExecutionStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Reject Execution Status
      .addCase(rejectExecutionStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(rejectExecutionStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Handle the response if needed
      })
      .addCase(rejectExecutionStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Assign Tracker
      .addCase(assignTracker.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assignTracker.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Handle the response if needed
      })
      .addCase(assignTracker.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch Plan Details
      .addCase(fetchPlan.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.plan = action.payload;
      })
      .addCase(fetchPlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch Brief Details
      .addCase(fetchBrief.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBrief.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.brief = action.payload;
      })
      .addCase(fetchBrief.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetPlan, resetBrief } = campaignSlice.actions;

export default campaignSlice.reducer;
