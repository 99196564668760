import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchDurationData = createAsyncThunk(
  "/fetchDurationData",
  async (plan_id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        `/fetch-plan-display-duration/client-appsells/${plan_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch duration data ");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching duration"
      );
    }
  }
);

const fetchDurationDataSlice = createSlice({
  name: "fetchDurationData",
  initialState: {
    data: {},
    status: "idle",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDurationData.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchDurationData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;

        state.loading = false;
      })
      .addCase(fetchDurationData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default fetchDurationDataSlice.reducer;
