import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";
import { recordBriefSlice } from "../recordBriefSlice";

export const fetchPlanCount = createAsyncThunk(
  "fetchPlanCount",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        "/fetch-count-plan-status-wise",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch Plan counts");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching Plan counts"
      );
    }
  }
);

const fetchPlanCountSlice = createSlice({
  name: "fetchPlanCount",
  initialState: {
    inprogressCount: 0,
    pendingCount: 0,
    approvedCount: 0,
    modificationCount: 0,
    lostCount: 0,
    status: "idle",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlanCount.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchPlanCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log(
          "inside succeed payload : " + JSON.stringify(action.payload)
        );
        state.inprogressCount = action.payload.inprogressPlansCount;
        state.pendingCount = action.payload.pendingPlansCount;
        state.approvedCount = action.payload.acceptedPlansCount;
        state.modificationCount = action.payload.modificationPlansCount; 
        state.lostCount = action.payload.rejectedPlansCount ;
        state.loading = false;
      })
      .addCase(fetchPlanCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default fetchPlanCountSlice.reducer;
