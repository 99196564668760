import React, { useRef, useEffect, useState } from "react";
import { Form, Input, Row, Col, Button ,message ,Spin} from "antd";
import { useForm } from "antd/es/form/Form";
import CustomSelect from "../ClientManagement/CustomSelect";
import { LeftCircleOutlined } from "@ant-design/icons";

import {
  billingVendorOptions,
  executionTypeOptions,
  siteVendorNames,
} from "../../../utils/vendorOptions";
import {
  createSite,
  fetchAssetType,
  fetchLsmList,
  fetchMedium,
  fetchPrintingMediaType,
  fetchSecClass,
  getSpecificSite,
  resetCreateSiteApi,
  resetUpdateSiteApi,
  updateSite,
} from "../../../features/siteManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addRequestedSite } from "../../../features/addrequestedSiteSlice";
const status = [
  { label: "Yes", value: "Y" },
  { label: "No", value: "N" },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AddRequestedSiteDetails() {
  const dispatch = useDispatch();
  const query = useQuery();
  const siteId = query.get("siteId");
  const [form] = useForm();
  const formRef = useRef();
  const navigate = useNavigate();
  const [proceedLoading ,setProceedLoading] = useState(false) ;
  const {
    fetchSecClassApi,
    fetchMediumApi,
    fetchAssetTypeApi,
    fetchPrintingMediaTypeApi,
    fetchLsmListApi,
    createSiteApi,
    getSpecificSiteApi,
    updateSiteApi,
  } = useSelector((res) => res.siteManagement);

  useEffect(() => {
    dispatch(fetchSecClass());
    dispatch(fetchMedium());
    dispatch(fetchAssetType());
    dispatch(fetchLsmList());
    dispatch(fetchPrintingMediaType());
  }, [dispatch]);

  const handleSubmit = async(values) => {
    console.log("values:", JSON.stringify(values));
    const mediumObj = fetchMediumApi?.response?.allMediums?.find(
      (obj) => obj.medium_id === values?.medium
    );
    const assetObj = fetchAssetTypeApi?.response?.assetTypes?.find(
      (obj) => obj.asset_id === values?.asset_type
    );

    const requestData = {
      medium_id: values?.medium,
      medium_name: mediumObj?.medium_name || "",
      asset_id: values?.asset_type,
      asset_type: assetObj?.asset_type || "",
      idx_number: values?.idxNumber || "",
      sec_id: values?.sec_class || "",
      LSM_id: values?.life_style_management || "",
      execution_type: values?.execution_style || "",
      google_address: `${values?.latitude},${values?.longitude}`,
      printing_media_id: values?.printing_media_type || "",
      site_vendor: values?.site_vendor || "",
      site_vendor_cost: values?.site_vendor_cost || "",
      illumination_status: values?.illumination_status || "",
    };

    console.log("Raw requestData:", requestData);

    // if (siteId) {
    //   // Dispatch addRequestedSite with the siteId and requestData (raw data)
    //   dispatch(addRequestedSite({ formData: requestData, id: siteId }));
    // }
    if (siteId) {
      try {
        setProceedLoading(true) ;
        // Dispatch the action and unwrap the result
        const result = await dispatch(
          addRequestedSite({ formData: requestData, id: siteId })
        ).unwrap();
  
        // Show success message
        message.success(result.message || "Site request added successfully!");
        navigate(`/site-management?tab=1`) ;
      } catch (error) {
        // Show error message
        message.error(
          error.message || "An error occurred while adding the requested site."
        );
      } finally {
        setProceedLoading(false) ;

      }
    } else {
      message.warning("No site ID provided.");
    }
  };
 
  return (
    <>
      <Row>
        <LeftCircleOutlined
          style={{
            color: "#294799",
            fontSize: "20px",
            marginTop: "47px",
            paddingLeft: "16px",
          }}
          onClick={() => navigate(-1)}
        />
        <h1
          style={{
            margin: "0px",
            padding: "0px",
            color: "#294799",
            fontSize: "20px",
            fontFamily: "gothamBook",
            paddingLeft: "15px",
            marginTop: "45px",
          }}
        >
          Sites Management
        </h1>
      </Row>
      <hr
        style={{
          padding: "0px",
          margin: "0px",
          border: "none",
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          marginTop: "10px",
          boxShadow: "none",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "82vh",
          overflow: "hidden", // Prevent overflow of the container
        }}
      >
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            style={{ padding: "16px", width: "80%" }}
            ref={formRef}
          >
            <h2
              style={{
                padding: "0px",
                margin: "0px 0px 20px 0px",
                color: "#294799",
                fontSize: "18px",
                fontFamily: "gothamBook",
              }}
            >
              Please add site data
            </h2>

            {/* Row  1 */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                gap: 15,
              }}
            >
              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    IDX Number
                  </span>
                }
                name="idxNumber"
                rules={[
                  {
                    pattern: /^(?:[0-9]+|N\/A)$/i, // Allows numbers or 'N/A', case-insensitive
                    message: "Input must be a number or 'N/A'!",
                  },
                ]}
              >
                <Input
                  className="custom-add-requested-site-input"
                  placeholder="e.g 798723"
                />
                {/* <Input className="custom-add-requested-site-input"Number placeholder="e.g 798723"  /> */}
              </Form.Item>

              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Execution Type
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select an execution type!",
                  },
                ]}
                name="execution_style"
              >
                <CustomSelect
                  className="custom-add-requested-site-input"
                  list={executionTypeOptions.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) =>
                    form.setFieldsValue({ execution_style: value })
                  }
                  placeholder="Select an execution type"
                />
              </Form.Item>

              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Address (Lat)
                  </span>
                }
                rules={[
                  { required: true, message: "Please enter latitude!" },
                  {
                    pattern: /^[0-9]*\.?[0-9]+$/,
                    message: "Please enter a valid latitude!",
                  },
                ]}
                name="latitude"
              >
                <Input
                  className="custom-add-requested-site-input"
                  placeholder="e.g 24.897232342061123"
                />
              </Form.Item>
            </div>

            {/* Row  2 */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                gap: 15,
              }}
            >
              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Address (Long)
                  </span>
                }
                rules={[
                  { required: true, message: "Please enter longitude!" },
                  {
                    pattern: /^[0-9]*\.?[0-9]+$/,
                    message: "Please enter a valid longitude!",
                  },
                ]}
                name="longitude"
              >
                <Input
                  className="custom-add-requested-site-input"
                  placeholder="e.g 24.897232342061123"
                />
              </Form.Item>

              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    LSM
                  </span>
                }
                rules={[{ required: true, message: "Please select an LSM!" }]}
                name="life_style_management"
              >
                <CustomSelect
                  className="custom-add-requested-site-input"
                  list={
                    fetchLsmListApi?.response?.lsm_list?.map((item) => ({
                      value: item?.LSM_id,
                      label: item?.LSM_type,
                    })) || []
                  }
                  onChange={(value) =>
                    form.setFieldsValue({ life_style_management: value })
                  }
                  placeholder="Select an LSM"
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    SEC Class
                  </span>
                }
                rules={[
                  { required: true, message: "Please select an SEC class!" },
                ]}
                name="sec_class"
              >
                <CustomSelect
                  className="custom-add-requested-site-input"
                  list={
                    fetchSecClassApi?.response?.allSecClassRanges?.map(
                      (item) => ({
                        value: item?.sec_id,
                        label: item?.sec_range,
                      })
                    ) || []
                  }
                  onChange={(value) =>
                    form.setFieldsValue({ sec_class: value })
                  }
                  placeholder="Select a SEC class"
                />
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                gap: 15,
              }}
            >
              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Medium
                  </span>
                }
                rules={[{ required: true, message: "Please select a medium!" }]}
                name="medium"
              >
                <CustomSelect
                  className="custom-add-requested-site-input"
                  list={
                    fetchMediumApi?.response?.allMediums?.map((item) => ({
                      value: item?.medium_id,
                      label: item?.medium_name,
                    })) || []
                  }
                  onChange={(value) => form.setFieldsValue({ medium: value })}
                  placeholder="Select a medium"
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Printing Media
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select a printing media type!",
                  },
                ]}
                name="printing_media_type"
              >
                <CustomSelect
                  className="custom-add-requested-site-input"
                  list={
                    fetchPrintingMediaTypeApi?.response?.PrintingMediaTypes?.map(
                      (item) => ({
                        value: item?.printing_media_id,
                        label: item?.printing_media_type,
                      })
                    ) || []
                  }
                  onChange={(value) =>
                    form.setFieldsValue({ printing_media_type: value })
                  }
                  placeholder="Select a printing media type"
                />
              </Form.Item>

              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Asset Type
                  </span>
                }
                rules={[
                  { required: true, message: "Please select an asset type!" },
                ]}
                name="asset_type"
              >
                <CustomSelect
                  className="custom-add-requested-site-input"
                  list={
                    fetchAssetTypeApi?.response?.assetTypes?.map((item) => ({
                      value: item?.asset_id,
                      label: item?.asset_type,
                    })) || []
                  }
                  onChange={(value) =>
                    form.setFieldsValue({ asset_type: value })
                  }
                  placeholder="Select an Asset Type"
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                gap: 15,
              }}
            >
              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Site Vendor
                  </span>
                }
                rules={[
                  { required: true, message: "Please select a site vendor!" },
                ]}
                name="site_vendor"
              >
                <CustomSelect
                  className="custom-add-requested-site-input"
                  list={siteVendorNames.map((item) => ({
                    value: item,
                    label: item,
                  }))} // Map the vendor options to the expected format
                  value={form.getFieldValue("vendor")} // Adjust based on your form state management
                  onChange={(value) => form.setFieldsValue({ vendor: value })} // Update form value
                  placeholder="Select a vendor"
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Site Vendor Cost
                  </span>
                }
                rules={[
                  { required: true, message: "Please enter vendor cost!" },
                  {
                    pattern: /^[0-9]*\.?[0-9]+$/,
                    message: "Please enter a valid cost!",
                  },
                ]}
                name="site_vendor_cost"
              >
                <Input
                  className="custom-add-requested-site-input"
                  placeholder="e.g 100000"
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1, marginRight: "8px" }}
                label={
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Illumination Status
                  </span>
                }
                rules={[{ required: true, message: "Please select a status!" }]}
                name="illumination_status"
              >
                <CustomSelect
                  className="custom-add-requested-site-input"
                  list={status.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))} // Map the status array to the expected format
                  value={form.getFieldValue("status")} // Adjust based on your form state management
                  onChange={(value) => form.setFieldsValue({ status: value })} // Update form value
                  placeholder="Select a status"
                />
              </Form.Item>
            </div>
          </Form>
        </div>

        <div style={{ marginTop: "auto" }}>
          <Row style={{ marginRight: "30px", justifyContent: "flex-end" }}>
            <Col>
              <Row
                gutter={8}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                }}
              >
                <Button
                  className="secondary-btn"
                  type="default"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <Button
                  className="blue-btn"
                  type="primary"
                  htmlType="submit"
                  onClick={() => formRef.current.submit()}
                >
                 {proceedLoading ? <Spin /> : 'Proceed'}

                  {/* Proceed  */}
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
