// src/features/signup/signupSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const addVideoLink = createAsyncThunk(
  "video/ ",
  async ({id, link}) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.post(
        `/add-highres-link/${id}`,
        { highres_link: link },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(
        "Error adding design link:",
        error.response?.data?.message || error.message
      );
      throw error;
    }
  }
);

const uploadVideoSlice = createSlice({
  name: "video",
  initialState: {
    videolink: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addVideoLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addVideoLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.designlink = action.payload; // Store only user data
      })
      .addCase(addVideoLink.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default uploadVideoSlice.reducer;
