import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchAnalysisData = createAsyncThunk(
  "allSites/fetchAnalysisData",
  async ({ plan_id }, { rejectWithValue }) => {
    console.log("analysis api hit ...") ;
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.get(
        `/fetch-pre-campaign-analysis-form/${plan_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch pre campaign data");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message ||
          "An error occurred while fetching pre campaign form data"
      );
    }
  }
);

const fetchAnalysisDataSlice = createSlice({
  name: "fetchAnalysisData",
  initialState: {
    analysisData: "",
    loading: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalysisData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAnalysisData.fulfilled, (state, action) => {
        state.loading = false;
        state.analysisData =  action.payload.preCampaignAnalysis;
        
      })
      .addCase(fetchAnalysisData.rejected, (state, action) => {
        state.loading = false;
        state.analysisData = {
          error: action.payload,
        };
      });
  },
});

export default fetchAnalysisDataSlice.reducer;
