import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";
// Thunk to fetch pending plans
export const fetchPendingPlans = createAsyncThunk(
  "pendingPlans/fetch",
  async (plan_id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        `/fetch-pending-plans-admin/${plan_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch pending plans");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching pending plans"
      );
    }
  }
);

// Slice to manage pending plans state
const pendingPlansSlice = createSlice({
  name: "pendingPlans",
  initialState: {
    totalPlans: null,
    httpRequest: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPendingPlans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPendingPlans.fulfilled, (state, action) => {
        state.totalPlans = action.payload.totalPlans;
        state.httpRequest = action.payload;
        state.loading = false;
      })
      .addCase(fetchPendingPlans.rejected, (state, action) => {
        state.error = action.payload ;
        state.loading = false;
      });
  },
});

export default pendingPlansSlice.reducer;
