import React, { useEffect } from "react";
import "../ConsolidatedTab/consolidatedStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchDigitalSummary } from "../../../../../features/PlanSummary/fetchDigitalSummarySlice";
import { useParams } from "react-router-dom";
import ConsolidatedSummary from "../ConsolidatedTab/ConsolidatedSummary";

export default function DigitalTabContent({plan1 ,plan2}) {
  const dispatch = useDispatch();
  // const { plan1, plan2, loading } = useSelector(
  //   (state) => state.fetchDigitalSummary
  // );
  const { plan_id } = useParams();

  // useEffect(() => {
  //   dispatch(fetchDigitalSummary(plan_id));
  // }, [dispatch, plan_id]);

  // Check if plan1 and plan2 are valid objects (not undefined or empty)
  const hasValidPlans =
    plan1 &&
    plan2 &&
    Object.keys(plan1).length > 0 &&
    Object.keys(plan2).length > 0;

  return (
    <div className="consolidated-tab-container">
      {hasValidPlans ? (
        <ConsolidatedSummary
          summaryData={plan1}
          title1="Digital Summary"
          mediumData={plan2}
          title2="Digital Campaign Medium Wise"
        />
      ) : (
        <div>No data available.</div>
      )}
    </div>
  );
}
