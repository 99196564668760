import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../httpService/httpService";

const initialState = {
  loading: false,
  totalRequested : 0 ,
  getSitesData: {},
  getRequestedSitesData : {},
  getSpecificSiteApi: {},
  assignTrackerApi: {},
  fetchSecClassApi: {},
  fetchMediumApi: {},
  fetchAssetTypeApi: {},
  fetchPrintingMediaTypeApi: {},
  fetchLsmListApi: {},
  createSiteApi: {},
  updateSiteApi: {},
  deleteSiteApi: {},
};

// Async thunk for getting all sites data
export const getSites = createAsyncThunk(
  "allSites/getSites",
  async ({ page = 1, limit = 10, newSearchText }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.get("/admin/fetch-approved-sites", {
        params: {
          page,
          limit,
          ...newSearchText,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch sites");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching sites"
      );
    }
  }
);
// For getting all requested sites
export const getRequestedSites = createAsyncThunk(
  "allSites/getRequestedSites",
  async ({ page = 1, limit = 10, newSearchText }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.get("/admin/fetch-pending-sites", {
        params: {
          page,
          limit,
          ...newSearchText,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch sites");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching sites"
      );
    }
  }
);
// Async thunk for getting specific sites data
export const getSpecificSite = createAsyncThunk(
  "allSites/getSpecificSite",
  async (siteId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.get(`/admin/fetch-site/${siteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch sites");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching sites"
      );
    }
  }
);

// Fetch sec class
export const fetchSecClass = createAsyncThunk(
  "allSites/fetchSecClass",
  async () => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(
      `/sec-class/fetch-all-sec-range-admin`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Fetch medium
export const fetchMedium = createAsyncThunk(
  "allSites/fetchMedium",
  async () => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(
      `/medium/fetch-all-mediums-admin`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Fetch Age Range
export const fetchAgeRange = createAsyncThunk(
  "allSites/fetchAgeRange",
  async () => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(`/appsells/fetch-age-ranges`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

export const fetchGenders = createAsyncThunk(
  "allSites/fetchGenders",
  async () => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(`/appsells/fetch-genders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);
// appsells/fetch-genders
// Fetch asset types
export const fetchAssetType = createAsyncThunk(
  "allSites/fetchAssetType",
  async () => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(`/appsells/fetch-asset-Types`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

// Fetch printing media types
export const fetchPrintingMediaType = createAsyncThunk(
  "allSites/fetchPrintingMediaType",
  async () => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(
      `/appsells/fetch-printing-media-types`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// Fetch LSM list
export const fetchLsmList = createAsyncThunk(
  "allSites/fetchLsmList",
  async () => {
    const token = localStorage.getItem("authToken");
    const response = await axiosInstance.get(`/appsells/fetch-lsm-list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

// Async thunk for assign tracker to sites
export const assignTracker = createAsyncThunk(
  "allSites/update",
  async ({ data }, { rejectWithValue }) => {
    console.log(data);
    try {
      const response = await axiosInstance.post(
        `/admin/assign-tracker/appsells-sites`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (!response.data) {
        return rejectWithValue("Failed to assign tracker");
      }

      return response.data; // You might want to return the full response data
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred during the assigning";
      return rejectWithValue(message);
    }
  }
);

// Async thunk for creating new sites
export const createSite = createAsyncThunk(
  "allSites/createSite",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/admin/create-site-appsells`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (!response.data) {
        return rejectWithValue("Failed to assign tracker");
      }

      return response.data; // You might want to return the full response data
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred during the assigning";
      return rejectWithValue(message);
    }
  }
);

// Async thunk for updating sites
export const updateSite = createAsyncThunk(
  "allSites/updateSite",
  async ({ data, id }, { rejectWithValue }) => {
    console.log("update called ...") ;
    try {
      const response = await axiosInstance.put(
        `/admin/update-site/appsells/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (!response.data) {
        return rejectWithValue("Failed to assign tracker");
      }

      return response.data; // You might want to return the full response data
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred during the assigning";
      return rejectWithValue(message);
    }
  }
);

// Async thunk for deleting sites
export const deleteSite = createAsyncThunk(
  "site/deleteSite",
  async (siteId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `/admin/delete-site/appsells/${siteId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (!response.data) {
        return rejectWithValue("Failed to delete site");
      }

      return response?.data; 
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred during the deletion";
      return rejectWithValue(message);
    }
  }
);

const siteManagementSlice = createSlice({
  name: "siteManagement",
  initialState,
  reducers: {
    /** reset assign tracker api */
    resetAssignTrackerApi: (state) => {
      state.assignTrackerApi = {};
    },
    /** reset create site api */
    resetCreateSiteApi: (state) => {
      state.createSiteApi = {};
    },
    /** reset update site api */
    resetUpdateSiteApi: (state) => {
      state.updateSiteApi = {};
    },
    /** reset delete site api */
    resetDeleteSiteApi: (state) => {
      state.deleteSiteApi = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSites.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSites.fulfilled, (state, action) => {
        state.loading = false;
        state.getSitesData = {
          response: action.payload,
        };
      })
      .addCase(getSites.rejected, (state, action) => {
        state.loading = false;
        state.getSitesData = {
          error: action.payload,
        };
      })
      .addCase(getRequestedSites.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRequestedSites.fulfilled, (state, action) => {
        state.loading = false;
        state.getRequestedSitesData = {
          response: action.payload,
        };
        state.totalRequested = action.payload.totalSites ;
      })
      .addCase(getRequestedSites.rejected, (state, action) => {
        state.loading = false;
        state.getRequestedSitesData= {
          error: action.payload,
        };
      })
      /** assign tracker */
      .addCase(assignTracker.pending, (state) => {
        state.assignTrackerApi = {
          loading: true,
        };
      })
      .addCase(assignTracker.fulfilled, (state, action) => {
        state.assignTrackerApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(assignTracker.rejected, (state, action) => {
        state.assignTrackerApi = {
          loading: false,
          error: action.payload,
        };
      }) /** fetch sec class */
      .addCase(fetchSecClass.pending, (state) => {
        state.fetchSecClassApi = {
          loading: true,
        };
      })
      .addCase(fetchSecClass.fulfilled, (state, action) => {
        state.fetchSecClassApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(fetchSecClass.rejected, (state, action) => {
        state.fetchSecClassApi = {
          loading: false,
          error: action.payload,
        };
      }) /** fetch medium */
      .addCase(fetchMedium.pending, (state) => {
        state.fetchMediumApi = {
          loading: true,
        };
      })
      .addCase(fetchMedium.fulfilled, (state, action) => {
        state.fetchMediumApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(fetchMedium.rejected, (state, action) => {
        state.fetchMediumApi = {
          loading: false,
          error: action.payload,
        };
      })
      // Fetch age ranges 
      .addCase(fetchAgeRange.pending, (state) => {
        state.fetchAgeRangeApi = {
          loading: true,
        };
      })
      .addCase(fetchAgeRange.fulfilled, (state, action) => {
        state.fetchAgeRangeApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(fetchAgeRange.rejected, (state, action) => {
        state.ffetchAgeRangeApi = {
          loading: false,
          error: action.payload,
        };
      })
      // fetchGenders
      .addCase(fetchGenders.pending, (state) => {
        state.fetchGendersApi = {
          loading: true,
        };
      })
      .addCase(fetchGenders.fulfilled, (state, action) => {
        state.fetchGendersApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(fetchGenders.rejected, (state, action) => {
        state.fetchGendersApi = {
          loading: false,
          error: action.payload,
        };
      })
      /** fetch asset type*/
      .addCase(fetchAssetType.pending, (state) => {
        state.fetchAssetTypeApi = {
          loading: true,
        };
      })
      .addCase(fetchAssetType.fulfilled, (state, action) => {
        state.fetchAssetTypeApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(fetchAssetType.rejected, (state, action) => {
        state.fetchAssetTypeApi = {
          loading: false,
          error: action.payload,
        };
      })
      /** fetch Printing media type*/
      .addCase(fetchPrintingMediaType.pending, (state) => {
        state.fetchPrintingMediaTypeApi = {
          loading: true,
        };
      })
      .addCase(fetchPrintingMediaType.fulfilled, (state, action) => {
        state.fetchPrintingMediaTypeApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(fetchPrintingMediaType.rejected, (state, action) => {
        state.fetchPrintingMediaTypeApi = {
          loading: false,
          error: action.payload,
        };
      })
      /** fetch Printing media type*/
      .addCase(fetchLsmList.pending, (state) => {
        state.fetchLsmListApi = {
          loading: true,
        };
      })
      .addCase(fetchLsmList.fulfilled, (state, action) => {
        state.fetchLsmListApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(fetchLsmList.rejected, (state, action) => {
        state.fetchLsmListApi = {
          loading: false,
          error: action.payload,
        };
      })
      /** create site*/
      .addCase(createSite.pending, (state) => {
        state.createSiteApi = {
          loading: true,
        };
      })
      .addCase(createSite.fulfilled, (state, action) => {
        state.createSiteApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(createSite.rejected, (state, action) => {
        // console.log("action.payload : " + JSON.stringify(action.payload)) ;
        state.createSiteApi = {
          loading: false,
          error: action.payload,
        };
      })
      /** update site*/
      .addCase(updateSite.pending, (state) => {
        state.updateSiteApi = {
          loading: true,
        };
      })
      .addCase(updateSite.fulfilled, (state, action) => {
        state.updateSiteApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(updateSite.rejected, (state, action) => {
        state.updateSiteApi = {
          loading: false,
          error: action.payload,
        };
      })
      /** delete site*/
      .addCase(deleteSite.pending, (state) => {
        state.deleteSiteApi = {
          loading: true,
        };
      })
      .addCase(deleteSite.fulfilled, (state, action) => {
        state.deleteSiteApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(deleteSite.rejected, (state, action) => {
        state.deleteSiteApi = {
          loading: false,
          error: action.payload,
        };
      })
      /** get specific site*/
      .addCase(getSpecificSite.pending, (state) => {
        state.getSpecificSiteApi = {
          loading: true,
        };
      })
      .addCase(getSpecificSite.fulfilled, (state, action) => {
        state.getSpecificSiteApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(getSpecificSite.rejected, (state, action) => {
        state.getSpecificSiteApi = {
          loading: false,
          error: action.payload,
        };
      });
  },
});
export const {
  resetAssignTrackerApi,
  resetCreateSiteApi,
  resetDeleteSiteApi,
  resetUpdateSiteApi,
} = siteManagementSlice.actions;
export default siteManagementSlice.reducer;
