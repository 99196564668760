import React from "react";
import { Table, Typography } from "antd";
import "../ConsolidatedTab/ConsolidatedSummary.css";

const { Title } = Typography;
const DurationTable = ({ apiResponse, title }) => {
  console.log("reponse is------------------: " + JSON.stringify(apiResponse));

  const formatNumber = (num) => {
    if (num === undefined || num === null) {
      return "-"; // Return a placeholder for undefined or null values
    }

    num = Number(num);

    const roundedNum = Math.round(num * 100) / 100; // Round to 2 decimal places

    if (roundedNum >= 1e12) {
      return (roundedNum / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
    } else if (roundedNum >= 1e9) {
      return (roundedNum / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
    } else if (roundedNum >= 1e6) {
      return (roundedNum / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
    } else if (roundedNum >= 1e3) {
      return (roundedNum / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
    } else {
      return roundedNum.toString(); // Less than 1000
    }
  };

  const summaryColumns = [
    {
      title: "Display Duration",
      dataIndex: "range",
      key: "range",
      className: "left-align",
      width: "10%",
      render: (text) => <div>{text}</div>, // Render the conventional text
    },
    {
      title: "Sites",
      dataIndex: "count",
      key: "count",
      width: "10%",
      render: (text) => <div>{text}</div>, // Render the quantity
    },
    {
      title: "%age Share",
      dataIndex: "percentage",
      key: "percentage",
      width: "10%",
      render: (text) => <div>{text}%</div>, // Render the square feet
    },
  ];

const ranges = ["07 Days (FOC)", "14-18 Days", "21 Days", "30 Days"];
const tableData = apiResponse.focStats.map((item, index) => ({
    key: index + 1,          // Unique key for each range row
    range: ranges[index],    // Use ranges array for the range value
    count: item.count,
    percentage: item.percentage,
  }));
  
  // Add total row
  tableData.push({
    key: "total",             // Unique key for total row
    range: "Total",
    count: apiResponse.total.count,
    percentage: apiResponse.total.percentage,
  });
  
  return (
    <div className="table-wrapper-3">
      <Title
        level={4}
        style={{ textAlign: "center" }}
        className="table-heading"
      >
        {title}
      </Title>
      <Table
        className="summary-table"
        columns={summaryColumns}
        dataSource={tableData}
        pagination={false}
        rowClassName={(record) => (record.key === "total" ? "total-row" : "")}
        bordered
      />
    </div>
  );
};
export default DurationTable;
