import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

// Fetch Competitors Action
export const fetchCompetitors = createAsyncThunk(
  "/fetchCompetitors",
  async ({ competitor_name }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        `/fetch-all-competitors/admin/appsells?competitor_name=${competitor_name}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred while fetching competitors"
      );
    }
  }
);


const fetchCompetitorsSlice = createSlice({
  name: "fetchCompetitors",
  initialState: {
    competitors: [],
    status: "idle",
    error: null,
    loading : true ,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetitors.pending, (state) => {
        state.status = "loading";
        state.loading = true ;
      })
      .addCase(fetchCompetitors.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.competitors = action.payload;
        state.loading = false ;
      })
      .addCase(fetchCompetitors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false ;
      })
     
  },
});

export default fetchCompetitorsSlice.reducer;
