import React, { useEffect } from "react";
import "../ConsolidatedTab/consolidatedStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchBreakUpSummary } from "../../../../../features/PlanSummary/fetchBreakUpSummarySlice";
import { useParams } from "react-router-dom";

import BreakUpTable from "./BreakUpTable";
import CustomPieChart from "../Charts/FOC_Graph";
import { Col } from "antd";
import DurationTable from "./DurationTable";

export default function BreakUpTabContent({ data, durationData }) {
  const dispatch = useDispatch();

  // Check if data and plan are valid objects
  const hasValidPlans =
    data &&
    data.plan &&
    Array.isArray(data.plan.sites) &&
    data.plan.sites.length > 0;

  const percentages = {
    foc_percentage: data?.plan?.foc_percentage,
    paid_percentage: data?.plan?.paid_percentage,
  };
  console.log("percentages : " + JSON.stringify(percentages));
  const colors = ["#FFA500", "#8CCB7E"]; // Define any colors you need
  const labels = ["FOC", "Paid"]; // Define your labels

  return (
    <div className="consolidated-tab-container">
      {hasValidPlans && percentages ? (
        <div style={{ display: "flex", gap: "5%", flexDirection: "column" }}>
          <Col style={{ display: "flex", gap: "20px" }}>
            <BreakUpTable apiResponse={data} title="Break up Paid vs FOC" />
            <DurationTable
              apiResponse={durationData}
              title="Display Duration %age"
            />
          </Col>
          <CustomPieChart
            percentages={percentages}
            colors={colors}
            labels={labels}
            title="Paid vs FOC"
          />
        </div>
      ) : (
        <div>No data available.</div>
      )}
    </div>
  );
}
