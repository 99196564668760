// src/features/clientSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";
export const deleteApprovedImage = createAsyncThunk(
  "/deleteApprovedImage",
  async ({ site_id, plan_id, image_ids }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.delete(`/delete-plan-site-images/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Ensuring the request content type is JSON
        },
        data: {
          site_id,
          plan_id,
          image_ids,
        },
      });

      if (!response.data) {
        return rejectWithValue("Failed to delete uploaded image");
      }
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred during the deletion";
      return rejectWithValue(message);
    }
  }
);

const deleteApprovedImageSlice = createSlice({
  name: "deleteApprovedImage",
  initialState: {
    status: null,
    error: null,
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteApprovedImage.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(deleteApprovedImage.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
      })
      .addCase(deleteApprovedImage.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default deleteApprovedImageSlice.reducer;
