import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

// export const uploadSiteImage = createAsyncThunk(
//   "/uploadSiteImage",
//   async ( {site_id, plan_id, image}, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("authToken");
//         console.log("inside upload api") ;
//       const response = await axiosInstance.post(
//         "/client/upload-site-images/tracker",
//         {
//           site_id,
//           plan_id,
//           image, 
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
  
//       if (response.status === 200 || response.status === 201) {
//         return {
//           data: response.data,
//           headers: {
//             contentType: response.headers["content-type"],
//             contentLength: response.headers["content-length"],
//           },
//         };
//       } else {
//         throw new Error("Image Upload Failed: " + response.status);
//       }
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
  
// );

export const uploadSiteImage = createAsyncThunk(
    "/uploadSiteImage",
    async (formData, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("authToken");
        console.log("inside upload API");
  
        const response = await axiosInstance.post(
          "/upload-site-images/operational-analyst",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",  // Set content type for FormData
            },
          }
        );
  
        if (response.status === 200 || response.status === 201) {
          return {
            data: response.data,
            headers: {
              contentType: response.headers["content-type"],
              contentLength: response.headers["content-length"],
            },
          };
        } else {
          throw new Error("Image Upload Failed: " + response.status);
        }
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  
const uploadSiteImageSlice = createSlice({
  name: "uploadSiteImage",
  initialState: {
    uploadedImg : {},
    isLoading: false,
    error: null,
  },
  reducers: {
    resetStatusCreate: (state) => {
      state.status = "idle";
      state.error = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadSiteImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadSiteImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.uploadedImg = action.payload; // Store only user data
      })
      .addCase(uploadSiteImage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});



export default uploadSiteImageSlice.reducer;
