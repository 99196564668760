import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchDigitalSummary = createAsyncThunk(
  "/fetchDigitalSummary",
  async ( plan_id , { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(`/fetch-digital-plan-summary/client-appsells/${plan_id}`, {
        
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch digital plan summary");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching digital plan summary"
      );
    }
  }
);

const fetchDigitalSummarySlice = createSlice({
  name: "fetchDigitalSummary",
  initialState: {
    plan1: {},
    plan2: {},
    status: "idle",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDigitalSummary.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchDigitalSummary.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.plan1 = action.payload.consolidated_ooh_plan_summary;
        state.plan2 = action.payload.plan_medium_summary;
        state.loading = false;
      })
      .addCase(fetchDigitalSummary.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default fetchDigitalSummarySlice.reducer;
