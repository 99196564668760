// src/features/signup/signupSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const addCompetitor = createAsyncThunk(
  "competitor/addCompetitor",
  async (userData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.post(
        "/create-competitor/admin/appsells",
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        const res = response;

        return {
          data: res.data,
          headers: {
            contentType: response.headers["content-type"],
            contentLength: response.headers["content-length"],
          },
        };
      } else {
        // Handle non-success responses
        throw new Error(
          "Add Competitor failed with status: " + response.status
        );
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const addCompetitorSlice = createSlice({
  name: "addCompetitor",
  initialState: {
    competitor: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    resetStatusCreate: (state) => {
      state.status = "idle";
      state.error = null;
      state.user = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCompetitor.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addCompetitor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.competitor = action.payload;
        state.error = null;
      })
      .addCase(addCompetitor.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetStatusCreate } = addCompetitorSlice.actions;

export default addCompetitorSlice.reducer;
