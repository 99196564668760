// src/features/planSlice.js
import { createSlice } from "@reduxjs/toolkit";

const planNameSlice = createSlice({
  name: "plan_name",
  initialState: {
    planName: "", // Default value
  },
  reducers: {
    setPlanName(state, action) {
      state.planName = action.payload; // Update the planName with the payload
    },
  },
});


export const { setPlanName } = planNameSlice.actions;

export default planNameSlice.reducer;
