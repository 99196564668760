import {
  setError,
  setLoading,
  clearAllErrors,
} from "../features/globalErrorSlice.js";


export const errorMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  // if (action.type?.endsWith("/pending")) {
  //   const baseType = action.type.replace("/pending", "");
  //   store.dispatch(setLoading({ actionType: baseType, isLoading: true }));
  // }
  if (action.type?.endsWith("/pending")) {
    const baseType = action.type.replace("/pending", "");
    store.dispatch(setLoading({ actionType: baseType, isLoading: true }));
    return result; // Avoid additional checks
  }

  if (action.type?.endsWith("/fulfilled")) {
    const baseType = action.type.replace("/fulfilled", "");
    store.dispatch(setLoading({ actionType: baseType, isLoading: false }));
    store.dispatch(clearAllErrors());
  }

  if (action.type?.endsWith("/rejected")) {
    const baseType = action.type.replace("/rejected", "");
    store.dispatch(setLoading({ actionType: baseType, isLoading: false }));

    // const error = action.payload || action.error;
    const error = action.payload || action.error || {};
    if (!Object.keys(error).length) return result; // Skip if no error data

    console.log("Error in middleware:", error);

    const isNetworkIssue =
      !error.response && // No response from the server
      (error?.code === "ERR_NETWORK" ||
        error?.message === "Network Error" ||
        error?.code === "ERR_CONNECTION_REFUSED" ||
        error?.code === "ERR_NAME_NOT_RESOLVED");

    const isBackendError =
      error?.response?.status >= 400 && error?.response?.status < 500  || error?.code ==='ERR_CONNECTION_TIMED_OUT';

    if (isNetworkIssue) {
      store.dispatch(
        setError({
          actionType: baseType,
          error: "Network Issue! Please check your connection.",
        })
      );
    } else if (isBackendError) {
      // Optional: Ignore or log the error
      console.warn("Backend error:", error.response?.data || error.message);
    } else {
      store.dispatch(
        setError({
          actionType: baseType,
          error: "Network Issue! Please check your connection.",
        })
      );
    }
  }

  return result;
};
