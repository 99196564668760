import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";


export const fetchMapData = createAsyncThunk(
    "/fetchMapData",
    async ({plan_id}, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await axiosInstance.get(
          `/fetch-plan-grouped-sites/${plan_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (!response.data || response.status !== 200) {
          throw new Error("Failed to fetch map data ");
        }
  
        return response.data;
      } catch (error) {
        return rejectWithValue(
          error.message || "An error occurred while data for map"
        );
      }
    }
  );
  
const fetchMapDataSlice = createSlice({
  name: "fetchMapData",
  initialState: {
    mapdata: {},
    totalItems: 0,
    status: "idle",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMapData.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchMapData.fulfilled, (state, action) => {
        console.log("inside fullfilled") ;
        state.status = "succeeded";
        state.mapdata = action.payload.plan.sitesGroupedByCity;

        state.loading = false;
      })
      .addCase(fetchMapData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default fetchMapDataSlice.reducer;
