// export default CustomPieChart;
import React, { useEffect, useState, useRef } from "react";
import { Typography, Spin } from "antd";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const { Title } = Typography;

const renderLegend = (props, data) => {
  const { payload } = props;
  return (
    <ul style={{ listStyleType: "none", padding: 0, textAlign: "left" }}>
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <div
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: entry.color,
              marginRight: "8px",
            }}
          />
          <span
            style={{
              fontSize: "14px",
              color: "#3E3E3E",
              fontWeight: "500",
              fontFamily: "gothamBook",
            }}
          >
            {entry.name || data[index].name}
          </span>
        </li>
      ))}
    </ul>
  );
};

const CustomPieChart = ({
  percentages,
  colors,
  labels,
  title = "FOC - Conventional vs Digital",
}) => {
  const COLORS = colors;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (percentages && Object.keys(percentages).length > 0) {
      const newData = Object.keys(percentages).map((key, index) => {
        const percentageValue = percentages[key];
        const value =
          typeof percentageValue === "string"
            ? parseFloat(percentageValue.replace("%", "").trim())
            : 0;

        return {
          name: labels[index],
          value: value,
        };
      });

      setData(newData);

      // Simulate loading time for the chart rendering
      setTimeout(() => {
        setLoading(false);
      }, 500); // Delay in milliseconds to mimic chart loading
    }
  }, [percentages, labels]);

  // const renderLabel = ({ cx, cy, midAngle, outerRadius, value }) => {
  //   const RADIAN = Math.PI / 180;
  //   const x = cx + (outerRadius / 2) * Math.cos(-midAngle * RADIAN);
  //   const y = cy + (outerRadius / 2) * Math.sin(-midAngle * RADIAN);

  //   return (
  //     <text
  //       x={x}
  //       y={y}
  //       fill="white"
  //       textAnchor="middle"
  //       dominantBaseline="middle"
  //       fontSize={14}
  //     >
  //       {value}%
  //     </text>
  //   );
  // };
  const renderLabel = ({ cx, cy, midAngle, outerRadius, value }) => {
    const RADIAN = Math.PI / 180;
    const x = cx + (outerRadius / 2) * Math.cos(-midAngle * RADIAN);
    const y = cy + (outerRadius / 2) * Math.sin(-midAngle * RADIAN);

    return value > 0 ? ( // Only render label if value is greater than 0
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14}
      >
        {value}%
      </text>
    ) : null; // If the value is 0, don't render anything
  };

  return (
    <div
      style={{ textAlign: "center", width: "45%" }}
      className="pie-chart-container1"
    >
      <Title level={4} className="table-heading">
        {title}
      </Title>

      {loading ? (
        <Spin tip="Loading chart..." />
      ) : data.length > 0 ? (
        <PieChart className="piechart-container" width={400} height={200}>
          <Pie
            data={data}
            cx={100}
            cy={100}
            outerRadius={80}
            dataKey="value"
            label={renderLabel}
            labelLine={false}
            isAnimationActive={false}
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
          </Pie>
          <Tooltip content={null} />
          <Legend
            content={(props) => renderLegend(props, data)}
            layout="vertical"
            align="right"
            verticalAlign="middle"
          />
        </PieChart>
      ) : (
        <span>No data available</span>
      )}
    </div>
  );
};

export default CustomPieChart;
