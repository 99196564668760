import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

// Fetch Data for MIR Report
export const fetchReport = createAsyncThunk(
  "/fetchReport",
  async ({ competitor_campaign_id, reportType }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        `/fetch-single-report/admin/appsells`,
        {
          params: {
            reportType,
            competitor_campaign_id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message ||
          "An error occurred while fetching competitor campaigns sites"
      );
    }
  }
);

const fetchReportSlice = createSlice({
  name: "fetchReport",
  initialState: {
    data: [],

    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log("action.payload : " + JSON.stringify(action.payload.report.competitorreports));
        state.data = action.payload;
      })

      .addCase(fetchReport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default fetchReportSlice.reducer;
