import React, { useEffect, useState } from "react";
import { Collapse, Button, Row, Col } from "antd";
import "./summary.css";
import ConsolidatedTabContent from "./ConsolidatedTab/ConsolidatedTabContent";
import DigitalTabContent from "./Digital/DigitalTabContent";
import ConventionalTabContent from "./ConventionalTab/ConventionalTabContent";
import BreakUpTabContent from "./BreakUp";
import FinancialsTabContent from "./Financials";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchFinancialGraph } from "../../../../features/PlanSummary/fetchFinancialGraphSlice";
import { fetchFinancialsSummary } from "../../../../features/PlanSummary/fetchFinancialsSummarySlice";
import { fetchBreakUpSummary } from "../../../../features/PlanSummary/fetchBreakUpSummarySlice";
import { fetchConsolidatedSummary } from "../../../../features/PlanSummary/fetchconsolidatedSummarySlice";
import { fetchConventionalSummary } from "../../../../features/PlanSummary/fetchConventionalSummarySlice";
import { fetchDigitalSummary } from "../../../../features/PlanSummary/fetchDigitalSummarySlice";
import {fetchDurationData}  from "../../../../features/PlanSummary/fetchDurationDataSlice.js";

export default function PlanSummaryContainer() {
  const dispatch = useDispatch();
  const { plan_id } = useParams();

  // Redux state selectors
  const { data: financialsData } = useSelector(
    (state) => state.fetchFinancialsSummary
  );
  const { plan1: consolidatedPlan1, plan2: consolidatedPlan2 } = useSelector(
    (state) => state.fetchConsolidateSummary
  );
  const { plan1: digitalPlan1, plan2: digitalPlan2 } = useSelector(
    (state) => state.fetchDigitalSummary
  );
  const { plan1: conventionalPlan1, plan2: conventionalPlan2 } = useSelector(
    (state) => state.fetchConventionalSummary
  );
  const { data: breakUpData } = useSelector(
    (state) => state.fetchBreakUpSummary
  );
  const { conventional_plan, digital_plan } = useSelector(
    (state) => state.fetchFinancialGraph
  );

  const { data: durationData } = useSelector(
    (state) => state.fetchDurationData
  );
  // State for loader
  const [loading, setLoading] = useState(false);
  const [exportClicked, setExportClicked] = useState(false);

  useEffect(() => {
    if (plan_id) {
      dispatch(fetchFinancialsSummary(plan_id));
      dispatch(fetchFinancialGraph(plan_id));
      dispatch(fetchDurationData(plan_id));
      dispatch(fetchConsolidatedSummary(plan_id));
      dispatch(fetchConventionalSummary(plan_id));
      dispatch(fetchDigitalSummary(plan_id));
      dispatch(fetchBreakUpSummary(plan_id));
    }
  }, [dispatch, plan_id]);

  const generatePDF = () => {
    setLoading(true); // Start loading
    setExportClicked(true); // Set export clicked state

    // Add the pdf-view class to adjust styles for PDF generation
    const pdfContent = document.getElementById("pdf-content");
    pdfContent.classList.add("pdf-view"); // Add the class to enable full-width styles

    // Hide columns with a specific class name (e.g., "remove-column")
    const columnsToRemove = pdfContent.querySelectorAll(".remove-column");
    columnsToRemove.forEach((column) => {
      column.style.display = "none"; // Hide the column
    });

    const input = document.getElementById("pdf-content");
    html2canvas(input, { scale: 2 }) // Adjust scale as necessary
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "a4",
        });

        const imgWidth = pdf.internal.pageSize.getWidth() - 40;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const pageHeight = pdf.internal.pageSize.getHeight();
        let heightLeft = imgHeight;
        let position = 20;

        // Add images while the height exceeds the page height
        while (heightLeft > 0) {
          pdf.addImage(imgData, "PNG", 20, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
          position -= pageHeight; // Move to the next page
          if (heightLeft > 0) {
            pdf.addPage(); // Add a new page if there's still content
          }
        }

        pdf.save("plan-summary.pdf");

        // Restore the hidden columns after generating the PDF
        columnsToRemove.forEach((column) => {
          column.style.display = ""; // Reset display property
        });

        // Clean up: remove the class after generating the PDF
        pdfContent.classList.remove("pdf-view");
        setLoading(false); // Stop loading
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        alert("Failed to generate PDF. Please try again.");
        pdfContent.classList.remove("pdf-view"); // Remove the class on error
        setLoading(false); // Stop loading on error

        // Restore the hidden columns if an error occurs
        columnsToRemove.forEach((column) => {
          column.style.display = ""; // Reset display property
        });
      });
  };

  const items =
    financialsData &&
    conventional_plan &&
    digital_plan &&
    consolidatedPlan1 &&
    consolidatedPlan2 &&
    conventionalPlan1 &&
    conventionalPlan2 &&
    digitalPlan1 &&
    digitalPlan2 &&
    breakUpData
      ? [
          {
            key: "1",
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ width: "186px" }}> Plan Financials</span>
                <hr
                  style={{
                    flexGrow: 1,
                    height: "1px",
                    backgroundColor: "#AAAAAA",
                    border: "none",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
              
              </div>
            ),
            children: (
              <FinancialsTabContent
                conventional_plan={conventional_plan}
                digital_plan={digital_plan}
                data={financialsData}
              />
            ),
          },
          {
            key: "2",
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ width: "150px" }}> Consolidated</span>
                <hr
                  style={{
                    flexGrow: 1,
                    height: "1px",
                    backgroundColor: "#AAAAAA",
                    border: "none",
                    marginLeft: "10px",
                  }}
                />
              </div>
            ),
            children: (
              <ConsolidatedTabContent
                plan1={consolidatedPlan1}
                plan2={consolidatedPlan2}
              />
            ),
          },
          {
            key: "3",
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ width: "70px" }}> Digital</span>
                <hr
                  style={{
                    flexGrow: 1,
                    height: "1px",
                    backgroundColor: "#AAAAAA",
                    border: "none",
                    marginLeft: "10px",
                  }}
                />
              </div>
            ),
            children: (
              <DigitalTabContent plan1={digitalPlan1} plan2={digitalPlan2} />
            ),
          },
          {
            key: "4",
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ width: "150px" }}>Conventional</span>
                <hr
                  style={{
                    flexGrow: 1,
                    height: "1px",
                    backgroundColor: "#AAAAAA",
                    border: "none",
                    marginLeft: "10px",
                  }}
                />
              </div>
            ),
            // children: <ConventionalTabContent />,
            children: (
              <ConventionalTabContent
                plan1={conventionalPlan1}
                plan2={conventionalPlan2}
              />
            ),
          },
          {
            key: "5",
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ width: "324px" }}> Break Up (Paid Vs FOC)</span>
                <hr
                  style={{
                    flexGrow: 1,
                    height: "1px",
                    backgroundColor: "#AAAAAA",
                    border: "none",
                  }}
                />
              </div>
            ),
            // children: <BreakUpTabContent />,
            children: <BreakUpTabContent data={breakUpData} durationData={durationData}/>,
          },
        ]
      : [];

  return (
    <div>
      {/* Hidden content for capturing everything */}
      <div
        id="pdf-content"
        style={{
          position: "absolute",
          left: "-9999px", // Move off-screen
          top: "0",
          display: "block", // Ensure it is still displayed in the DOM
        }}
      >
        {items.map((item, index) => (
          <div key={index}>
            <h4>{item.label}</h4>
            {item.children}
          </div>
        ))}
      </div>

      {/* Visible collapsible UI for users */}
      {items.length > 0 ? (
        <>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col></Col>
            <Button
              type="default"
              style={{
                color: "#294799",
                border: "1px solid #294799",
                padding: "8px 16px",
                textDecoration: "none",
                fontFamily: "gothamMedium",
                fontWeight: "500",
                textAlign: "center",
                height: "40px",
                width: "114px",
              }}
              onClick={generatePDF}
              loading={loading}
              disabled={loading} // Disable when loading
            >
              Export
            </Button>
          </Row>
          <Collapse
            defaultActiveKey={["1"]}
            ghost
            items={items}
            className="custom-collapse"
            onChange={(key) => {
              if (exportClicked) {
                // Prevent toggle action if export was clicked
                setExportClicked(false); // Reset exportClicked state after handling
                return; // Do not toggle
              }
              // Allow toggle if export was not clicked
              // You can include any logic here for handling toggles if needed
            }}
          />
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
