import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errors: {},
  loading: {},
  activeRequests: 0
};

const globalErrorSlice = createSlice({
  name: "globalError",
  initialState,
  reducers: {
    setError: (state, action) => {
      const { actionType, error } = action.payload;
      state.errors[actionType] = error;
    },
    clearError: (state, action) => {
      delete state.errors[action.payload];
    },
    clearAllErrors: (state) => {
      state.errors = {};
    },
    setLoading: (state, action) => {
      const { actionType, isLoading } = action.payload;
      state.loading[actionType] = isLoading;
      state.activeRequests = isLoading 
        ? state.activeRequests + 1 
        : state.activeRequests - 1;
    }
  }
});

export const { setError, clearError, clearAllErrors, setLoading } = globalErrorSlice.actions;
export default globalErrorSlice.reducer;