import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../httpService/httpService";

export const createPassword = createAsyncThunk(
  "passowrd/createPassword",
  async ({ userData, token }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        "/user/update-password/appsells",
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // if (response.status === 200) {
      //   const { data } = response;

      //   return {
      //     userData: data,
      //     headers: {
      //       contentType: response.headers["content-type"],
      //       contentLength: response.headers["content-length"],
      //     },
      //   };
      // } else {
      //   // Handle non-success responses
      //   throw new Error("Signup failed with status: " + response.status);
      // }
      return {
        userData: response.data,
        headers: {
          contentType: response.headers["content-type"],
          contentLength: response.headers["content-length"],
        },
      };
    } catch (error) {
      return rejectWithValue({
        status: error.response?.status,
        data: error.response?.data,
      });
    }
  }
);

const createPasswordSlice = createSlice({
  name: "password",
  initialState: {
    user: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.userData;
        state.error = null;
      })
      .addCase(createPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.error = action.payload;
      });
  },
});

export default createPasswordSlice.reducer;
