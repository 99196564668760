
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";


// Fetch Competitor Campaigns Action
export const fetchCompetitorCampaigns = createAsyncThunk(
    "fetchCompetitorCampaigns/fetch", // Updated action type for clarity
    async ({ id , campaign_name }, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await axiosInstance.get(
          `/competitor-campaigns/admin/appsells/${id}?campaign_name=${campaign_name}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        return response.data; // axios will throw an error if status is not in the 2xx range
      } catch (error) {
        return rejectWithValue(
          error.message || "An error occurred while fetching competitor campaigns"
        );
      }
    }
  );


  const fetchCompetitorCampaignsSlice = createSlice({
    name: "fetchCompetitorCampaigns",
    initialState: {
      campaigns: [],
      status: "idle",
      error: null,
      loading : true , 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchCompetitorCampaigns.pending, (state) => {
          state.status = "loading";
      state.loading =  true ;
        })
        .addCase(fetchCompetitorCampaigns.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.campaigns= action.payload;
          state.loading =  false ;
        })
        .addCase(fetchCompetitorCampaigns.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.payload;
          state.loading =  false ;

        })
       
    },
  });
  
  export default fetchCompetitorCampaignsSlice.reducer;
  