// src/productsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchAlterPending = createAsyncThunk(
  "plan/AlterPending",
  async ({ page=1, limit=10 , search}, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        "/fetch-briefs-alter-pending-plans/admin-appsells",
        {
          params: { page, limit , search},
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch pending alter plans");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching plans"
      );
    }
  }
);

// export const pendingPlanApi = createAsyncThunk(
//   "plan/AlterPlanPending",
//   async ({ page=1, limit=10, search }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("authToken");
//       const response = await axiosInstance.get(
//         "/fetch-briefs-pending-plans-list/admin-appsells",
//         {
//           params: { page, limit, search },
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (!response.data || response.status !== 200) {
//         throw new Error("Failed to fetch plans");
//       }

//       return response.data;
//     } catch (error) {
//       return rejectWithValue(
//         error.message || "An error occurred while fetching plans"
//       );
//     }
//   }
// );

export const alterRequiredByBriefID = createAsyncThunk(
  "plan/alterRequiredByBriefID",
  async ({ brief_id, page=1, limit=10, search }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        `/fetch-plans-altered-pending/admin-appsells/${brief_id}`,
        {
          params: { page, limit, search },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch plans");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching plans"
      );
    }
  }
);

const modificationPendingSlice = createSlice({
  name: "AlterPending",
  initialState: {
    itemsPending: [],
    alterPlanById: [],
    totalPendingItems: 0,
    status: "idle",
    error: null,
    loading: false,
    pendingPlan: [],
    
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlterPending.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchAlterPending.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.itemsPending = action.payload.plans;
        state.totalPendingItems = action.payload.totalPlans;
        state.loading = false;
      })
      .addCase(fetchAlterPending.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      })
    //   .addCase(alterPlanByBriefID.pending, (state) => {
    //     state.status = "loading";
    //     state.loading = true;
    //   })
    //   .addCase(alterPlanByBriefID.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.alterPlanById = action.payload;
    //     state.totalItems = action.payload.totalPlans;
    //     state.loading = false;
    //   })
    //   .addCase(alterPlanByBriefID.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message;
    //     state.loading = false;
    //   })

    //   .addCase(pendingPlanApi.pending, (state, action) => {
    //     state.status = "loading";
    //     state.loading = true;
    //   })
    //   .addCase(pendingPlanApi.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.pendingPlan = action.payload;
    //     state.totalItems = action.payload.totalPlans;
    //     state.loading = false;
    //   })

    //   .addCase(pendingPlanApi.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message;
    //     state.loading = false;
    //   });
  },
});

export default modificationPendingSlice .reducer;
