import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../httpService/httpService";

export const addRequestedSite = createAsyncThunk(
  "requestedSites/addRequestedSite",
  async ({ formData, id }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/admin/approve-tracker-site/${id}`,
        formData, 
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json", 
          },
        }
      );

      if (!response.data) {
        return rejectWithValue("Failed to create requested site ");
      }

      return response.data; 
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred during requested site creation";
      return rejectWithValue(message);
    }
  }
);

const addRequestedSiteSlice = createSlice({
  name: "requestedSite",
  initialState: {
    site: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    resetStatusCreate: (state) => {
      state.status = "idle";
      state.error = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addRequestedSite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRequestedSite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.site = action.payload; // Store only user data
      })
      .addCase(addRequestedSite.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetStatusCreate } = addRequestedSiteSlice.actions;

export default addRequestedSiteSlice.reducer;
