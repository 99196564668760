import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

// Fetch Competitor Campaigns Action
export const fetchCompetitorSites = createAsyncThunk(
  "/fetchCompetitorSites", // Updated action type for clarity
  async ({ id , searchParams }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        `/competitor-campaign-sites/admin/appsells/${id}`,
        {
          params : {
           ...searchParams,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; // axios will throw an error if status is not in the 2xx range
    } catch (error) {
      return rejectWithValue(
        error.message ||
          "An error occurred while fetching competitor campaigns sites"
      );
    }
  }
);

const fetchCompetitorSitesSlice = createSlice({
  name: "fetchCompetitorSites",
  initialState: {
    data: [],
    competitorname: {},
    status: "idle",
    error: null,
    sites: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetitorSites.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompetitorSites.fulfilled, (state, action) => {
        state.status = "succeeded";
        // console.log("action.payload : " + JSON.stringify(action.payload.data)) ;
        state.data = action.payload.data;
        state.competitorname = action.payload?.data?.competitor_campaign_name;
        state.sites = action.payload?.data?.sites || [];
      })

      .addCase(fetchCompetitorSites.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default fetchCompetitorSitesSlice.reducer;
