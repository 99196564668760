import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CircularProgress, Box, Button } from "@mui/material";
import { Typography } from "antd";

const { Text } = Typography;
// const GlobalErrorHandler = ({ children }) => {
//   const { errors, activeRequests } = useSelector((state) => state.globalError);
//   const hasErrors = Object.keys(errors).length > 0;
//   const [showError, setShowError] = useState(false);
//   useEffect(() => {
//     let timeoutId;

//     if (hasErrors) {
//       // Wait for 3 seconds before showing the error screen
//       timeoutId = setTimeout(() => {
//         setShowError(true);
//       }, 3000);
//     } else {
//       setShowError(false);
//     }
//     // Cleanup timeout on component unmount or when error state changes
//     return () => {
//       if (timeoutId) {
//         clearTimeout(timeoutId);
//       }
//     };
//   }, [hasErrors]);
//   React.useEffect(() => {
//     console.log("Global Error State:", { errors, activeRequests, hasErrors });
//   }, [errors, activeRequests, hasErrors]);
//   const handleReload = () => {
//     window.location.reload();
//   };
//   return (
//     <>
//       {children}
//       {/* {activeRequests > 0 && (
//                 <Box
//                     sx={{
//                         position: 'fixed',
//                         top: 0,
//                         left: 0,
//                         right: 0,
//                         bottom: 0,
//                         backgroundColor: 'rgba(255, 255, 255, 0.7)',
//                         zIndex: 1400,
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                     }}
//                 >
//                     <CircularProgress />
//                 </Box>
//             )} */}
//       {showError && hasErrors && (
//         <Box
//           sx={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             backgroundColor: "rgba(255, 255, 255, 0.9)",

//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             gap: 2,
//           }}
//         >
//           <Typography
//             variant="h6"
//             color="error"
//             style={{ marginLeft: "270px" }}
//           >
//             Network Issue !
//           </Typography>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleReload}
//             sx={{
//               backgroundColor: "#294799",
//               "&:hover": {
//                 backgroundColor: "#1a2f66",
//               },
//               marginLeft: "270px",
//             }}
//           >
//             Reload
//           </Button>
//         </Box>
//       )}
//     </>
//   );
// };
// export default GlobalErrorHandler;

const GlobalErrorHandler = ({ children }) => {
  const { errors, activeRequests } = useSelector((state) => state.globalError);
  const hasNetworkError = Object.values(errors).some(
    (error) => error === "Network Issue! Please check your connection."
  );
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (hasNetworkError) {
      timeoutId = setTimeout(() => {
        setShowError(true);
      }, 3000);
    } else {
      setShowError(false);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [hasNetworkError]);
  React.useEffect(() => {
        console.log("Global Error State:", { errors, activeRequests, hasNetworkError});
      }, [errors, activeRequests, hasNetworkError]);
      const handleReload = () => {
        window.location.reload();
      };
  return (
    <>
      {children}
      {showError && hasNetworkError && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <img
            src="/images/nowifi.svg"
            alt="No Connection"
            style={{
              width: "150px",
              height: "87px",
              marginTop: "10px",
              marginLeft: "230px",
            }}
          />
          <div>
            <Typography
              variant="h6"
              color="error"
              style={{
                marginLeft: "295px",
                fontSize: "30px",
                padding: "2px",
                color: "#13357C",
                opacity: "0.7",
                letterSpacing: "5px",
                fontFamily: "gothamBook",
                fontWeight: 600,
              }}
            >
              Network Issue
            </Typography>
            <Typography
              style={{
                marginLeft: "230px",
                fontFamily: "gothamBook",
                fontWeight: 500,
              }}
            >
              {" "}
              It looks like you are not connected to internet. Check your
              network
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
            sx={{
              backgroundColor: "#294799",
              fontFamily: "gothamBook",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#1a2f66",
              },
              marginLeft: "229px",
            }}
          >
            Reload
          </Button>
        </Box>
      )}
    </>
  );
};

export default GlobalErrorHandler;
