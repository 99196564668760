// src/productsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

// export const createPlan = createAsyncThunk(
//   "plan/createPlan",
//   async ({ briefId, planName }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("authToken");
//       const response = await axiosInstance.post(
//         `/create-plan-name/admin-appsell/${briefId}`,
//         {
//           planName: planName,
//         }, // Empty object
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (!response.data || response.status !== 200) {
//         throw new Error("Failed to create plan");
//       }

//       return response.data;
//     } catch (error) {
//       return rejectWithValue(
//         error.message || "An error occurred while creating plan"
//       );
//     }
//   }
// );

export const createPlan = createAsyncThunk(
  "plan/createPlan",
  async ({ briefId, planName }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.post(
        `/create-plan-name/admin-appsell/${briefId}`,
        {
          planName: planName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        // Pass backend message to rejectWithValue
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message || "An error occurred while creating plan");
    }
  }
);

const createPlanSlice = createSlice({
  name: "createPlan",
  initialState: {
    items: [],
    status: "idle",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPlan.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload.plans;
        state.loading = false;
        state.error = null;
      })
      .addCase(createPlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default createPlanSlice.reducer;
