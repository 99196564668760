// src/features/clientSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const completeCampaign = createAsyncThunk(
    "campaign/completeCampaign",
    async (id, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await axiosInstance.patch(
          `/complete-campaign/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (!response.data) {
          return rejectWithValue("Failed to complete campaign");
        }
  
        return id;
      } catch (error) {
        const message =
          error.response?.data?.message ||
          "An error occurred during the completing campaign";
        return rejectWithValue(message);
      }
    }
  );
  
const completeCampaignSlice = createSlice({
  name: "campaign",
  initialState: {
    status: null,
    error: null,
    loading: false,
    message: null ,
  },

  reducers: {
    resetStatus: (state) => {
      state.status = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(completeCampaign.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(completeCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.message = "Campaign status updated successfully"
      })
      .addCase(completeCampaign.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetStatus } = completeCampaignSlice.actions;

export default completeCampaignSlice.reducer;
