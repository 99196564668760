import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Modal,
  Spin,
  Input,
  Space,
  Button,
  message,
} from "antd";
import "./table.css"; // Custom CSS for the calendar
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSpecificSite,
  deleteSite,
} from "../../../features/siteManagementSlice";
import { LeftCircleOutlined } from "@ant-design/icons";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const { Text } = Typography;

const ViewRequestedSite = () => {
  const query = useQuery();
  const siteId = query.get("siteId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const response = useSelector((res) => res.campaignDetail);
  const { getSpecificSiteApi } = useSelector((res) => res.siteManagement);

  useEffect(() => {
    if (siteId) {
      setIsLoading(true); // Set loading to true when starting the fetch
      dispatch(getSpecificSite(siteId));
    }
  }, [siteId]);

  useEffect(() => {
    if (getSpecificSiteApi && getSpecificSiteApi.response) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [getSpecificSiteApi]);

  const showImageModal = (imageSrc) => {
    console.log(imageSrc, "sss");
    setModalImage(imageSrc);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  console.log(getSpecificSiteApi, "getSpecificSiteApi");

  const handleProceed = () => {
    navigate(`./add-details?siteId=${siteId}`);
  };
  // const handleReject= () =>{
  //   if (siteId) {
  //      dispatch(deleteSite(siteId));
  //   }

  // }
  const handleReject = () => {
    if (siteId) {
      dispatch(deleteSite(siteId))
        .then((response) => {
          // Assuming response contains a success message
          if (response) {
            message.success("Site rejected successfully!");
            navigate(-1);
          } else {
            message.error(
              response?.data?.message || "Failed to reject the site."
            );
          }
        })
        .catch((error) => {
          // Handle the error
          message.error(
            error?.response?.data?.message ||
              "An error occurred while rejecting the site."
          );
        });
    } else {
      message.warning("No site selected for rejection.");
    }
  };

  return (
    <>
      <Row>
        <LeftCircleOutlined
          style={{
            color: "#294799",
            fontSize: "20px",
            marginTop: "47px",
            paddingLeft: "16px",
          }}
          onClick={() => navigate(-1)}
        />
        <h1
          style={{
            margin: "0px",
            padding: "0px",
            color: "#294799",
            fontSize: "20px",
            fontFamily: "gothamBook",
            paddingLeft: "15px",
            marginTop: "45px",
          }}
        >
          Sites Management
        </h1>
      </Row>
      <hr
        style={{
          padding: "0px",
          margin: "0px",
          border: "none",
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          marginTop: "10px",
          boxShadow: "none",
        }}
      />

      <div style={{ padding: "10px 10px 10px 20px" }}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
              marginTop: "25%",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: "20px",
              }}
            >
              <h1 style={{ color: "#294799", fontFamily: "gothamBook" }}>
                Requested Site
              </h1>
            </div>

            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Row gutter={[28, 16]}>
                <Col span={6}>
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "bold",
                      fontSize: "16.6px",
                    }}
                  >
                    Region(s){" "}
                  </span>
                  <Input
                    className="custom-requested-site-input"
                    value={getSpecificSiteApi?.response?.site?.region_name}
                    disabled
                  />
                </Col>
                <Col span={6}>
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "bold",
                      fontSize: "16.6px",
                    }}
                  >
                    City{" "}
                  </span>
                  <Input
                    className="custom-requested-site-input"
                    value={getSpecificSiteApi?.response?.site?.city_name}
                    disabled
                  />
                </Col>
                <Col span={6}>
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "bold",
                      fontSize: "16.6px",
                    }}
                  >
                    Area{" "}
                  </span>
                  <Input
                    className="custom-requested-site-input"
                    value={getSpecificSiteApi?.response?.site?.location_name}
                    disabled
                  />
                </Col>
              </Row>

              <Row gutter={[28, 16]}>
                <Col span={6}>
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "bold",
                      fontSize: "16.6px",
                    }}
                  >
                    Traffic Flow{" "}
                  </span>
                  <Input
                    className="custom-requested-site-input"
                    value={getSpecificSiteApi?.response?.site?.traffic_flow}
                    disabled
                  />
                </Col>
                <Col span={6}>
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "bold",
                      fontSize: "16.6px",
                    }}
                  >
                    Height{" "}
                  </span>
                  <Input
                    className="custom-requested-site-input"
                    value={getSpecificSiteApi?.response?.site?.height}
                    disabled
                  />
                </Col>
                <Col span={6}>
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "bold",
                      fontSize: "16.6px",
                    }}
                  >
                    Width{" "}
                  </span>
                  <Input
                    className="custom-requested-site-input"
                    value={getSpecificSiteApi?.response?.site?.width}
                    disabled
                  />
                </Col>
              </Row>

              <Row gutter={[28, 16]}>
                <Col span={6}>
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "bold",
                      fontSize: "16.6px",
                    }}
                  >
                    Total Sq.ft{" "}
                  </span>
                  <Input
                    className="custom-requested-site-input"
                    value={getSpecificSiteApi?.response?.site?.total_sqft}
                    disabled
                  />
                </Col>
                <Col span={6}>
                  <span
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "gothamBook",
                      fontWeight: "bold",
                      fontSize: "16.6px",
                    }}
                  >
                    Tracker{" "}
                  </span>
                  <Input
                    className="custom-requested-site-input"
                    value={
                      getSpecificSiteApi?.response?.site?.user?.fullName ||
                      "Not Assigned"
                    }
                    disabled
                  />
                </Col>
              </Row>
            </Space>

            {/* <h1
              style={{
                color: "#294799",
                fontFamily: "gothamBook",
                fontSize: "20px",
                marginTop: "45px",
              }}
            >
              Site Images
            </h1>
            <Row gutter={[14, 14]}>
              {getSpecificSiteApi?.response?.site?.siteimages?.length > 0 ? (
                getSpecificSiteApi?.response?.site?.siteimages?.map((item) => (
                  <Col span={4} key={item.image_id}>
                    <img
                      src={item.day_view}
                      alt="Approved Image"
                      style={{ width: "100%", height: "150px" }}
                      onClick={() => showImageModal(item.day_view)}
                    />
                  </Col>
                ))
              ) : (
                <Col span={24}>
                  <Text>No Images</Text>
                </Col>
              )}
            </Row>

            <Row>
              <Col></Col>{" "}
              <Col>
                <Row>
                  <Button>Reject</Button>
                  <Button>Proceed</Button>
                </Row>
              </Col>
            </Row> */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "28vh",
              }}
            >
              <div style={{ flexGrow: 1 }}>
                <h1
                  style={{
                    color: "#294799",
                    fontFamily: "gothamBook",
                    fontSize: "20px",
                    marginTop: "45px",
                  }}
                >
                  Site Images
                </h1>
                <Row gutter={[14, 14]}>
                  {getSpecificSiteApi?.response?.site?.siteimages?.length >
                  0 ? (
                    getSpecificSiteApi?.response?.site?.siteimages?.map(
                      (item) => (
                        <Col span={4} key={item.image_id}>
                          <img
                            src={item.day_view}
                            alt="Approved Image"
                            style={{ width: "100%", height: "150px" }}
                            onClick={() => showImageModal(item.day_view)}
                          />
                        </Col>
                      )
                    )
                  ) : (
                    <Col span={24}>
                      <Text>No Images</Text>
                    </Col>
                  )}
                </Row>
              </div>

              <div style={{ marginTop: "auto" }}>
                <Row
                  style={{ marginRight: "30px", justifyContent: "flex-end" }}
                >
                  <Col>
                    <Row
                      gutter={8}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "15px",
                      }}
                    >
                      <Button
                        className="secondary-btn"
                        type="default"
                        onClick={() => handleReject()}
                      >
                        Reject
                      </Button>
                      <Button
                        className="blue-btn"
                        type="primary"
                        onClick={() => handleProceed()}
                      >
                        Proceed
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        width={700}
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={modalImage} alt="Enlarged" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

export default ViewRequestedSite;
