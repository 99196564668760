// src/productsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

// export const  fetchPlanVersion2 = createAsyncThunk(
//   "plan/ fetchPlanVersion2",
//   async ({ plan_id, city_ids }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("authToken");
//       console.log(" inside  fetchPlanVersion2 plan_id : " + plan_id);
//       const response = await axiosInstance.get(
//         `/visualrep-excelsheet/${plan_id}`,
//         {
//           params: {
//             city_ids,
//           },
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (!response.data || response.status !== 200) {
//         throw new Error("Failed to fetch plans");
//       }

//       return response.data;
//     } catch (error) {
//       return rejectWithValue(
//         error.message || "An error occurred while fetching plans"
//       );
//     }
//   }

// );

export const fetchPlanVersion2 = createAsyncThunk(
  "plan/fetchPlanVersion2",
  async ({ plan_id, city_ids }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      console.log("inside fetchPlanVersion2 plan_id:", plan_id);

      // Make a POST request instead of GET
      const response = await axiosInstance.post(
        `/visualrep-excelsheet/${plan_id}`,
        { city_ids }, // Sending city_ids in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // Ensure the server knows we're sending JSON
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch plans");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching plans"
      );
    }
  }
);

const fetchPlanVersion2Slice = createSlice({
  name: "fetchPlanVersion2",
  initialState: {
    plan: {},
    status: "idle",
    error: null,
    loading: false,
    headers: [],
    cities: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlanVersion2.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchPlanVersion2.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.plan = action.payload.plan;
        state.headers = action.payload.dynamicHeaders;
        state.cities = action.payload.plan.citiesArray;
        state.loading = false;
      })
      .addCase(fetchPlanVersion2.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default fetchPlanVersion2Slice.reducer;
