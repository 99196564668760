import React, { useState } from "react";
import { Select } from "antd";
// import {  Select , MenuItem} from '@mui/material';
import { CaretDownOutlined } from "@ant-design/icons";
const { Option } = Select;
const CustomSelect = ({
  list,
  value,
  onChange,
  placeholder = "",
  disabled = false,
  multiTag = false,
  maxTagCount = 1, 
  maxTagTextLength= 12,
  style = {},
  className ="",
}) => {
  const [open, setOpen] = useState(false);

  // Handle dropdown open/close
  const handleDropdownVisibleChange = (visible) => {
    setOpen(visible);
  };

  // Handle icon click to toggle dropdown
  const handleIconClick = (e) => {
    e.stopPropagation(); // Prevent the click from closing the dropdown
    setOpen((prev) => !prev); // Toggle the dropdown state
  };
  const handleSelectChange = (selectedValue) => {
    onChange(selectedValue); // Notify parent of value change
  };

  return (
    <Select
      mode={multiTag ? "multiple" : undefined}
      value={value}
      open={open}
      placeholder={placeholder}
      onChange={handleSelectChange} // Attach onChange
      onDropdownVisibleChange={handleDropdownVisibleChange}
      suffixIcon={
        <div onClick={handleIconClick} style={{ cursor: "pointer" }}>
          <CaretDownOutlined style={{ fontSize: "15px", color: "#292D32" }} />
        </div>
      }
      disabled={disabled}
      style={style}
      maxTagCount={maxTagCount} // Set maxTagCount
      maxTagTextLength={maxTagTextLength}
      className={className}
    >
      {list.map((item) => (
        <Option key={item.value} value={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
