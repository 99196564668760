// vendorOptions.js

// Billing Vendor Options
const billingVendorOptions = [
  "Media 99",
  "Hafiz Bro",
  "Sahar ad",
  "MNK",
  "Art vision",
  "AA",
  "One ten",
  "Out reach",
  "Hub",
  "Lahore ad",
  "Ravi",
  "Topaz",
  "Sherazi",
  "Flexo Sign",
  "Orange Ad",
  "Al Nasir",
  "Top Sign",
  "Signways",
  "Painter Babo",
  "Brand recall",
  "Media touch",
  "NMS",
  "Value ad",
  "Super Sign",
  "AW Sons",
  "Pindi Ad",
  "Classic ad",
  "Sign Pakistan",
  "The Sign",
  "Dimmension",
  "Mikab ad",
  "Ad Net",
  "Omlete",
  "Saad Communication",
  "Universal Ad",
  "Maaz",
  "Chinab ad",
  "Ad Care",
  "Siddique sign",
  "Younas Ad",
];

const billingVendorOptionsObjects = billingVendorOptions.map((option) => ({
  value: option,
  label: option,
}));

// Printing Vendor Options
const printingVendorOptions = [
  "Adsells",
  "Ad innovation",
  "Chinab Ad",
  "Classic Ad",
  "Sign System",
  "SLS",
  "Digirex",
  "Print Arena",
  "Ad saquad",
  "Ad Glow",
];

const printingVendorOptionsObjects = printingVendorOptions.map((option) => ({
  value: option,
  label: option,
}));

// Lightning Vendor Options
const lightningFabricationOptions = [
  "Adsells",
  "Ad innovation",
  "Chinab Ad",
  "Classic Ad",
  "Sign System",
  "SLS",
  "Digirex",
  "Print Arena",
  "Ad saquad",
  "Ad Glow",
];

const lightningFabricationVendorOptionsObjects =
  lightningFabricationOptions.map((option) => ({
    value: option,
    label: option,
  }));

// Permission Vendor Options
const permissionVendorOptions = [
  "Authority Payment Central",
  "Authority Payment South",
  "Authority Payment North",
];

const permissionVendorOptionsObjects = permissionVendorOptions.map(
  (option) => ({
    value: option,
    label: option,
  })
);

// Creative Vendor Options
const creativeVendor = [
  "Media 99",
  "Hafiz Bro",
  "Sahar ad",
  "MNK",
  "Art vision",
  "AA",
  "One ten",
  "Out reach",
  "Hub",
  "Lahore ad",
  "Ravi",
  "Topaz",
  "Sherazi",
  "Flexo Sign",
  "Orange Ad",
  "Al Nasir",
  "Top Sign",
  "Signways",
  "Painter Babo",
  "Brand recall",
  "Media touch",
  "NMS",
  "Value ad",
  "Super Sign",
  "AW Sons",
  "Pindi Ad",
  "Classic ad",
  "Sign Pakistan",
  "The Sign",
  "Dimmension",
  "Mikab ad",
  "Ad Net",
  "Omlete",
  "Saad Communication",
  "Universal Ad",
  "Maaz",
  "Chinab ad",
  "Ad Care",
  "Siddique sign",
  "Younas Ad",
];

const creativeVendorOptionsObjects = creativeVendor.map((option) => ({
  value: option,
  label: option,
}));

const executionTypeOptions = [
  "Conventional",
  "Digital",
  "Creative",
  "Static Streamer",
  "Transit Media",
];

const AssetTypeOptions = ["PAID", "FOC", "Temporary", "Fixed", "Bottler Asset"];
const AssetTypeOptionsObjects = AssetTypeOptions.map((option) => ({
  value: option,
  label: option,
}));
const siteVendorNames = [
  "Media 99",
  "Hafiz Bro",
  "Sahar ad",
  "MNK",
  "Art vision",
  "AA",
  "One ten",
  "Spectrum Lines",
  "Sahar Co",
  "MTS",
  "Out reach",
  "Universal Ad",
  "Hub",
  "Candid Ad",
  "Younas Ad",
  "Inter Ad",
  "Lahore ad",
  "Ravi Ad",
  "Kollag Ad",
  "TCF",
  "OBI",
  "Topaz",
  "Sherazi",
  "Flexo Sign",
  "Orange Ad",
  "Al Nasir",
  "Top Sign",
  "Signways",
  "Painter Babo",
  "Brand recall",
  "Media touch",
  "NMS",
  "Value ad",
  "Super Sign",
  "AW Sons",
  "Pindi Ad",
  "Classic ad",
  "Sign Pakistan",
  "The Sign",
  "Dimmension",
  "Mikab ad",
  "Ad Net",
  "Omlete",
  "Saad Communication",
  "Maaz",
  "Chinab ad",
  "Adcar",
  "Siddique sign",
  "Shahid Sign",
  "Ammar Advertising",
  "Admax",
  "Deplex",
  "Adchannel",
  "Zubair",
  "Call Advertiser",
  "Ransal Advertising",
  "Media Communication",
  "Graphic Edge",
  "Retail Solution",
  "Impulse",
  "Go Far Media",
  "Zaib Enterprises",
  "Chinab",
  "Millenium Advertising",
  "Kinetic",
  "Sagar Arts",
  "The Sign Media",
  "Zodiac",
  "Adcommunication",
  "M&B",
  "Amber Publicity",
];

const siteVendorOptions = siteVendorNames.map((name) => ({
  value: name,
  label: name,
}));


// Exporting all options
export {
  billingVendorOptions,
  executionTypeOptions,
  billingVendorOptionsObjects,
  printingVendorOptions,
  printingVendorOptionsObjects,
  lightningFabricationOptions,
  lightningFabricationVendorOptionsObjects,
  permissionVendorOptions,
  permissionVendorOptionsObjects,
  creativeVendor,
  creativeVendorOptionsObjects,
  AssetTypeOptionsObjects,
  AssetTypeOptions,
  siteVendorOptions ,
  siteVendorNames ,
};
