// src/features/clientSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../httpService/httpService";

export const activateUser = createAsyncThunk(
    "user/activateUser",
    async (clientId, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await axiosInstance.patch(
          `/user/activate-appsells/${clientId}`,
          {}, // No data is sent in the body, so use an empty object
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (!response.data) {
          return rejectWithValue("Failed to activate client");
        }
  
        return clientId; // Returning clientId to identify which client was activated
      } catch (error) {
        const message =
          error.response?.data?.message ||
          "An error occurred during the activation";
        return rejectWithValue(message);
      }
    }
  );
  
const activateUserSlice = createSlice({
  name: "user",
  initialState: {
    status: null,
    error: null,
    loading: false,
  },
  reducers: {
    resetStatus: (state) => {
      state.status = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(activateUser.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(activateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
      })
      .addCase(activateUser.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetStatus } = activateUserSlice.actions;

export default activateUserSlice.reducer;
