import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";
// Async thunk for updating a client
export const UpdateCompetitorSites = createAsyncThunk(
  "/UpdateCompetitorSites",
  async ({ competitor_sites }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/admin/update-competitor-campaign-sites/appsells/`,
        { competitor_sites },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (!response.data) {
        return rejectWithValue("Failed to update competitor campaign sites");
      }

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || "An error occurred during the update";
      return rejectWithValue(message);
    }
  }
);

const UpdateCompetitorSitesSlice = createSlice({
  name: "UpdateCompetitorSites",
  initialState: {
    status: null,
    error: null,
    data: null,
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateCompetitorSites.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(UpdateCompetitorSites.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(UpdateCompetitorSites.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default UpdateCompetitorSitesSlice.reducer;
