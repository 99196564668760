import React from "react";
import { Table, Typography } from "antd";
import "./ConsolidatedSummary.css";

const { Title } = Typography;

const ConsolidatedSummary = ({ summaryData, mediumData, title1, title2 }) => {
  const formatNumber = (num) => {
    if (num === undefined || num === null) {
      return "-"; // Return a placeholder for undefined or null values
    }

    // Convert to number in case it's passed as a string
    num = Number(num);

    // Round the number to avoid floating-point precision issues
    const roundedNum = Math.round(num * 100) / 100; // Round to 2 decimal places

    if (roundedNum >= 1e12) {
      return (roundedNum / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
    } else if (roundedNum >= 1e9) {
      return (roundedNum / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
    } else if (roundedNum >= 1e6) {
      return (roundedNum / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
    } else if (roundedNum >= 1e3) {
      return (roundedNum / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
    } else {
      return roundedNum.toString(); // Less than 1000
    }
  };
  const summaryColumns = [
    {
      title: "City",
      dataIndex: "city_name",
      render: (text, record) => {
        if (record.key === "oma" || record.key === "total_plan") {
          return {
            children: <div>{text}</div>,
            props: {
              colSpan: 5, // Merge with the next 4 cells
              style: { textAlign: "center" }, // Center-align the text
            },
          };
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        if (record.key === "oma" || record.key === "total_plan") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return <div>{formatNumber(text)}</div>;
      },
    },
    {
      title: "SOV",
      dataIndex: "sov",
      key: "sov",

      render: (text, record) => {
        if (record.key === "oma" || record.key === "total_plan") {
          return {
            children: <div>{formatNumber(text)}</div>,
            props: {
              colSpan: 0,
            },
          };
        }

        return <div>{formatNumber(text)}</div>;
      },
    },
    {
      title: "SOV%",
      dataIndex: "sov_percentage",
      key: "sov_percentage",

      // render: (text, record) => {
      //   if (record.key === "oma" || record.key === "total_plan") {
      //     return {
      //       props: {
      //         colSpan: 0,
      //       },
      //     };
      //   }
      //   return <div>{text}</div>;
      // },
      render: (text, record) => {
        console.log("text: " + text + " record : " + JSON.stringify(record));

        const numericValue =
          typeof text === "string"
            ? parseFloat(text.replace("%", "").trim())
            : typeof text === "number"
            ? text
            : 0;

        console.log("text: " + text + "record : " + JSON.stringify(record));
        if (record.key == "oma" || record.key === "total_plan") {
          return {
            props: {
              colSpan: 0,
            },
            children: <div>{formatNumber(numericValue)}</div>, // Correctly wrap in 'children'
          };
        }
        return <div>{formatNumber(numericValue)}%</div>;
      },
    },
    {
      title: "SOS",
      dataIndex: "sos",
      key: "sos",
      // width: '158px',
      render: (text, record) => {
        if (record.key === "oma" || record.key === "total_plan") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return <div>{formatNumber(text)}</div>;
      },
    },
    {
      title: "SOS%",
      dataIndex: "sos_percentage",
      key: "sos_percentage",

      render: (text, record) => {
        console.log("text: " + text + " record : " + JSON.stringify(record));

        const numericValue =
          typeof text === "string"
            ? parseFloat(text.replace("%", "").trim())
            : typeof text === "number"
            ? text
            : 0; // Default to 0 if text is neither a string nor a number

        console.log("text: " + text + "record : " + JSON.stringify(record));
        if (record.key == "oma" || record.key === "total_plan") {
          return {
            children: <div>{formatNumber(numericValue)}</div>, // Correctly wrap in 'children'
          };
        }
        return <div>{formatNumber(numericValue)}%</div>;
      },
    },
  ];

  // const mediumColumns = [
  //   { title: "City", dataIndex: "city_name", key: "city_name" },
  //   { title: "Hoardings", dataIndex: "hoarding", key: "hoarding" },
  //   { title: "SMD Screen", dataIndex: "smd_screen", key: "smd_screen" },
  //   {
  //     title: "Digital Pole Sign",
  //     dataIndex: "digital_pole_sign",
  //     key: "digital_pole_sign",
  //   },
  //   { title: "Mall Screens", dataIndex: "mall_screens", key: "mall_screens" },
  //   {
  //     title: "Mall Hanging Banner",
  //     dataIndex: "mall_hanging_banner",
  //     key: "mall_hanging_banner",
  //   },
  //   {
  //     title: "Static Streamer",
  //     dataIndex: "static_streamer",
  //     key: "static_streamer",
  //   },
  //   {
  //     title: "Mall Branding",
  //     dataIndex: "mall_branding",
  //     key: "mall_branding",
  //   },
  //   {
  //     title: "Building Wrap",
  //     dataIndex: "building_wrap",
  //     key: "building_wrap",
  //   },
  //   {
  //     title: "Indoor Branding",
  //     dataIndex: "indoor_branding",
  //     key: "indoor_branding",
  //   },
  //   {
  //     title: "Outdoor Branding",
  //     dataIndex: "outdoor_branding",
  //     key: "outdoor_branding",
  //   },
  //   {
  //     title: "Wall Branding",
  //     dataIndex: "wall_branding",
  //     key: "wall_branding",
  //   },
  //   {
  //     title: "Vehicle Branding",
  //     dataIndex: "vehicle_branding",
  //     key: "vehicle_branding",
  //   },
  //   {
  //     title: "Anamorphic Projection",
  //     dataIndex: "anamorphic_projection",
  //     key: "anamorphic_projection",
  //   },
  //   { title: "BQS Branding", dataIndex: "bqs_branding", key: "bqs_branding" },
  //   { title: "Wall Paint", dataIndex: "wall_paint", key: "wall_paint" },
  //   {
  //     title: "Location Branding",
  //     dataIndex: "location_branding",
  //     key: "location_branding",
  //   },
  //   {
  //     title: "Digital Pylons",
  //     dataIndex: "digital_pylons",
  //     key: "digital_pylons",
  //   },
  // ];

  const mediumColumns = [
    {
      title: "City",
      dataIndex: "city_name",
      key: "city_name",
      render: (text) => <div>{text}</div>, // No specific condition for city
    },
    {
      title: "Hoardings",
      dataIndex: "hoarding",
      key: "hoarding",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "SMD Screen",
      dataIndex: "smd_screen",
      key: "smd_screen",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Digital Pole Sign",
      dataIndex: "digital_pole_sign",
      key: "digital_pole_sign",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Mall Screens",
      dataIndex: "mall_screens",
      key: "mall_screens",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Mall Hanging Banner",
      dataIndex: "mall_hanging_banner",
      key: "mall_hanging_banner",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Static Streamer",
      dataIndex: "static_streamer",
      key: "static_streamer",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Mall Branding",
      dataIndex: "mall_branding",
      key: "mall_branding",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Building Wrap",
      dataIndex: "building_wrap",
      key: "building_wrap",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Indoor Branding",
      dataIndex: "indoor_branding",
      key: "indoor_branding",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Outdoor Branding",
      dataIndex: "outdoor_branding",
      key: "outdoor_branding",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Wall Branding",
      dataIndex: "wall_branding",
      key: "wall_branding",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Vehicle Branding",
      dataIndex: "vehicle_branding",
      key: "vehicle_branding",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Anamorphic Projection",
      dataIndex: "anamorphic_projection",
      key: "anamorphic_projection",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "BQS Branding",
      dataIndex: "bqs_branding",
      key: "bqs_branding",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Wall Paint",
      dataIndex: "wall_paint",
      key: "wall_paint",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Location Branding",
      dataIndex: "location_branding",
      key: "location_branding",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
    {
      title: "Digital Pylons",
      dataIndex: "digital_pylons",
      key: "digital_pylons",
      render: (text) => (
        <div className={text < 1 ? "value-less-than-one" : ""}>{text}</div>
      ),
    },
  ];

  const summaryDataSource = [
    ...summaryData.sites,
    {
      city_name: "Total",
      quantity: summaryData.total_quantity,
      sov: summaryData.total_sov,
      sov_percentage: summaryData.total_sov_percentage,
      sos: summaryData.total_sos,
      sos_percentage: summaryData.total_sos_percentage,
      key: "total",
    },
    {
      city_name: `OMA ${summaryData.oma_percentage * 100}%`,
      //   quantity: "",
      //   sov: "",
      //   sos: "",
      sos_percentage: summaryData.oma,
      key: "oma",
    },
    {
      city_name: "Total Plan",
      //   quantity: "",
      //   sov: "",
      //   sos: "",
      sos_percentage: summaryData.total_plan,
      key: "total_plan",
    },
  ];
  // Filter out columns with totals that are 0
  // const filteredMediumColumns = mediumColumns.filter((column, index) => {
  //   // Always include the first column (index 0)
  //   if (index === 0) return true;

  //   // Check if any site's value for the current column is greater than 0
  //   const hasValueGreaterThanZero = mediumData.sites.some((site) => {
  //     const value = site[column.dataIndex]; // Accessing the value dynamically
  //     console.log("mediumData : " + JSON.stringify(mediumData));
  //     console.log("value : " + value + " for col : " + column.dataIndex);
  //     return value > 0; // Check if the value is greater than 0
  //   });

  //   return hasValueGreaterThanZero; // Keep column if any value is greater than 0
  // });

  const filteredMediumColumns = mediumColumns.filter((column, index) => {
    if (index === 0) return true; // Always include the first column
    const hasValueGreaterThanZero = mediumData.sites.some((site) => {
      const value = site[column.dataIndex]; // Accessing the value dynamically
      return value > 0; // Check if the value is greater than 0
    });
    return hasValueGreaterThanZero; // Keep column if any value is greater than 0
  });
  const mediumDataSource = [
    ...mediumData.sites,
    {
      city_name: "Total",
      hoarding: mediumData.total_hoardings,
      smd_screen: mediumData.total_smd_screens,
      digital_pole_sign: mediumData.total_digital_pole_signs,
      mall_screens: mediumData.total_mall_screens,
      mall_hanging_banner: mediumData.total_hanging_banners,
      static_streamer: mediumData.total_streamers,
      building_wrap: mediumData.total_building_wraps,
      indoor_branding: mediumData.total_indoor_branding,
      outdoor_branding: mediumData.total_outdoor_branding,
      vehicle_branding: mediumData.total_vehicle_branding,
      anamorphic_projection: mediumData.total_anamorphic_projection,
      bqs_branding: mediumData.total_bqs_branding,
      wall_paint: mediumData.total_wall_paint,
      location_branding: mediumData.total_location_branding,
      digital_pylons: mediumData.total_digital_pylons,
      mall_branding: mediumData.total_mall_branding,
      wall_branding: mediumData.total_wall_branding,
      key: "total_medium",
    },
  ];

  return (
    <div className="consolidated-summary">
      <div className="summary-container">
        <div className="table-wrapper">
          <Title level={4} className="table-heading">
            {title1}
          </Title>
          <div className="summary-table">
            <Table
              // className="summary-table"
              columns={summaryColumns}
              dataSource={summaryDataSource}
              pagination={false}
              rowClassName={(record) =>
                record.key === "total" ||
                record.key === "oma" ||
                record.key === "total_plan"
                  ? "total-row"
                  : ""
              }
              bordered
            />
          </div>
        </div>
        <div className="table-wrapper">
          <Title level={4} className="table-heading">
            {title2}
          </Title>
          {/* <div className="summary-table-medium"> */}
            <Table
              className="summary-table-medium"
              columns={filteredMediumColumns} // Use filtered columns here
              dataSource={mediumDataSource}
              pagination={false}
              // rowClassName={(record) =>
              //   record.key === "total_medium" ? "total-row" : ""
              // }
              rowClassName={(record) => {
                const hasValueLessThanOne = Object.keys(record).some(
                  (key) => key !== "city_name" && record[key] < 1 // Check all columns except 'city_name'
                );
                // Check if the current row is total_medium or total_row
                const isTotalRow =
                  record.key === "total_medium" || record.key === "total_row";

                // Create an array to hold class names
                const classNames = [];

                // Add classes based on conditions
                if (hasValueLessThanOne) {
                  classNames.push("value-less-than-one");
                }

                if (isTotalRow) {
                  classNames.push("total-row"); // Add total-row class
                }

                // Join class names into a string and return
                return classNames.join(" ");
              }}
              bordered
          
            />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ConsolidatedSummary;
